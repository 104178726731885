<template>
  <section class="section--more-info mt-6">
    <v-row class="section__row">
      <v-col
        class="section__col"
        cols="2"
        v-for="(info, index) in sectionData"
        :key="index"
      >
        <p class="m500 mb-2">{{ info.title }}</p>
        <p class="section__description m700">{{ info.description }}</p>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  name: 'MoreInfoSection',
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.section__row {
  max-width: 1400px;
  margin-inline: auto;
  border-top: 1px solid var(--gray-2);
}

.section__col {
  border-left: 1px solid var(--gray-2);
  text-align: left;
  padding: 2rem;
}

.section__col:first-child {
  border: none;
}
</style>
