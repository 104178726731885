<template>
  <section class="section--stories">
    <v-row class="section__row">
      <v-col
        class="section__col"
        cols="12"
        lg="6"
        v-for="story in stories"
        :key="story.id"
      >
        <story-card
          :subTitle="story.subTitle"
          :title="story.title"
          :bg="story.bg"
          :slug="story.slug"
          :id="story.id"
        />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import blogs from '@/config/blogs';
import StoryCard from './StoryCard.vue';
export default {
  name: 'StoriesSection',
  components: {
    'story-card': StoryCard,
  },
  created() {
    this.stories = Object.values(blogs).map((item, index) => {
      return {
        id: String(index),
        slug: item.slug,
        bg: item.bg,
        subTitle: item.companyName,
        title: item.title,
      };
    });
  },
  data: () => ({
    stories: [],
  }),
};
</script>

<style scoped>
.section--stories {
  background-color: var(--light-1);
}
.section__row {
  max-width: 1400px;
  margin-inline: auto;
  padding-block: 3rem;
}
</style>
