<template>
  <section class="main-container">
    <div class="section__container">
      <div class="sticky-sidebar">
        <p class="sidebar-title text-left">How can you join our Talent Pool?</p>
      </div>
      <div class="contents">
        <div class="content__wrapper">
          <keep-alive>
            <v-img :src="require('@/assets/img/JP1.png')" class="content-icon"></v-img>
          </keep-alive>
          <p class="content__title">Screening call with our talent
            acquisition team</p>
        </div>
        <div class="content__wrapper">
          <keep-alive>
            <v-img :src="require('@/assets/img/JP2.png')" class="content-icon"></v-img>
          </keep-alive>
          <p class="content__title">Technical skills assessment with
            our enablement team</p>
        </div>
        <div class="content__wrapper">
          <keep-alive>
            <v-img :src="require('@/assets/img/JP3.png')" class="content-icon"></v-img>
          </keep-alive>
          <p class="content__title">Communication skills
            and cultural fit assessment</p>
        </div>
        <div class="content__wrapper">
          <keep-alive>
            <v-img :src="require('@/assets/img/JP4.png')" class="content-icon"></v-img>
          </keep-alive>
          <p class="content__title">Earn your verification badges
            and join our Talent pool</p>
        </div>
        <div class="content__wrapper">
          <keep-alive>
            <v-img :src="require('@/assets/img/resume.svg')" class="content-icon"></v-img>
          </keep-alive>
          <p class="content__title">Your Resume is shared with our clients</p>
        </div>
        <div class="content__wrapper">
          <keep-alive>
            <v-img :src="require('@/assets/img/interview-schedule.svg')" class="content-icon"></v-img>
          </keep-alive>
          <p class="content__title">Interview scheduled with Clients after they express interest</p>
        </div>
        <div class="content__wrapper">
          <keep-alive>
            <v-img :src="require('@/assets/img/hired.svg')" class="content-icon"></v-img>
          </keep-alive>
          <p class="content__title">Get Hired</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "NewMainContainer"
}
</script>

<style scoped>
.main-container {
  background-color: var(--primary-color-lighten);
}

.section__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media only screen and (min-width: 1024px) {
  .section__container {
    flex-direction: row;
  }
}

.content__wrapper {
  margin-bottom: 5rem;
  width: 100%;
  margin-left: auto;
  text-align: left;
}

@media only screen and (min-width: 1024px) {
  .content__wrapper {
    width: 60%;
  }
}

.sticky-sidebar {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  height: 100px;
  padding-block: 1rem;
  /* background-color: var(--primary-color-lighten); */
  background: linear-gradient(var(--primary-color-lighten) 75%, transparent 99%);
  z-index: 999;
}

@media only screen and (min-width: 1024px) {
  .sticky-sidebar {
    width: 40%;
    top: 150px;
    height: 150px;
  }
}

.sidebar-title {
  font-family: 'Outfit Bold', sans-serif;
  font-size: 1.5rem;
  color: var(--light-1);
  text-align: center !important;
}

@media only screen and (min-width: 1024px) {
  .sidebar-title {
    text-align: left !important;
    font-size: 2rem;
  }
}

.sidebar-description {
  font-family: "DM Sans Bold", sans-serif;
  font-size: 1rem;
  color: var(--light-1);
  text-align: center !important;
}

@media only screen and (min-width: 1024px) {
  .sidebar-description {
    text-align: left !important;
  }
}

.contents {
  width: 75%;
}

.content-icon {
  margin-bottom: 1rem;
  width: 50px;
  /* height: 70px; */
  aspect-ratio: 1;
}

.content__title {
  font-family: "Outfit Bold", sans-serif;
  font-size: 1.75rem;
  color: var(--light-1);
}

.marquee-section {
  display: block;
  height: 50px;
  margin-block: 3rem;
  background-image: url("@/assets/img/LOGOS_1824x48_re.png");
  background-size: cover;
  background-repeat: repeat-x;
  animation: marquee 2400s linear infinite;
  z-index: 0;
}

@keyframes marquee {
  0% {
    background-position-x: 0;
  }

  100% {
    background-position-x: 100000px;
  }
}
</style>
