<template>
  <section class="section__privacy-policy">
    <HeroSection
      :title="'DIGU Privacy Policy'"
      :description="`<span><strong>Last Updated on:</strong> 03/22/2023</span>`"
      :showWhiteWave="true"
    />
    <section class="content">
      <p>
        DIGU LLC (<strong>"Company" or "we"</strong>) respects your privacy and
        is committed to protecting it through our compliance with this policy.
      </p>
      <p>
        This policy describes the types of information we may collect from you
        or that you may provide when you visit the website,
        <a href="https://www.digu.one/">https://www.digu.one/</a>, including our
        client portal (our "<strong>Website</strong>") or our client portal and
        our practices for collecting, using, maintaining, protecting, and
        disclosing that information.
      </p>
      <p>This policy applies to information we collect:</p>
      <ul>
        <li>On this Website, including our client portal</li>
        <li>
          In email, text, and other electronic messages between you and the
          Company.
        </li>
      </ul>
      <p>It does not apply to information collected by:</p>
      <ul>
        <li>
          Us offline or through any other means, including on any other website
          operated by Company or any third party (including our affiliates and
          subsidiaries); or
        </li>
        <li>
          Any third party (including our affiliates and subsidiaries), including
          through any application or content (including advertising) that may
          link to or be accessible from or through the Website.
        </li>
      </ul>
      <p>
        Please read this policy carefully to understand our policies and
        practices regarding your information and how we will treat it. If you do
        not agree with our policies and practices, your choice is not to use our
        Website. By accessing or using this Website, you agree to this privacy
        policy. This policy may change from time to time (see
        <a href="#changes-to-our-privacy-policy">
          Changes to Our Privacy Policy </a
        >). Your continued use of this Website after we make changes is deemed
        to be acceptance of those changes, so please check the policy
        periodically for updates.
      </p>
      <h4><u>Children Under the Age of 13</u></h4>
      <p>
        Our Website is not intended for children under 13 years of age. No one
        under age 13 may provide any personal information to or on the Website.
        We do not knowingly collect personal information from children under 13.
        If you are under 13, do not use or provide any information on this
        Website or through any of its features (including the client portal), or
        provide any information about yourself to us, including your name,
        address, telephone number, email address, or any screen name or user
        name you may use. If we learn we have collected or received personal
        information from a child under 13 without verification of parental
        consent, we will delete that information. If you believe we might have
        any information from or about a child under 13, please contact us at
        <a href="mailto:info@digu.one">info@digu.one</a>.
      </p>
      <h3 class="mb-2"><u>Information We Collect</u></h3>
      <h4>
        <u>Information We Collect About You and How We Collect It</u>
      </h4>
      <p>
        We collect several types of information from and about users of our
        Website and our portal, including information:
      </p>
      <ul>
        <li>
          By which you may be personally identified, such as name, postal
          address, email address, telephone number, or any other identifier by
          which you may be contacted online or offline ("<strong
            >personal information</strong
          >"); and/or
        </li>
        <li>
          About your internet connection, the equipment you use to access our
          Website, and usage details.
        </li>
      </ul>
      <p>Personal information does not include:</p>
      <ul>
        <li>Publicly available information from government records.</li>
        <li>Deidentified or aggregated consumer information.</li>
      </ul>
      <p>We collect this information:</p>
      <ul>
        <li>Directly from you when you provide it to us.</li>
        <li>
          Automatically as you navigate through the site. Information collected
          automatically may include usage details, IP addresses, and information
          collected through cookies and web beacons.
        </li>
        <li>
          From third parties, for example, our business partners and finders.
        </li>
      </ul>
      <p>The information we collect on or through our Website may include:</p>
      <ul>
        <li>
          Information that you provide by filling in forms on our Website. This
          includes information provided at the time of registering and inputting
          information on the client portal.
        </li>
        <li>
          Records and copies of your correspondence (including email addresses)
          if you contact us.
        </li>
      </ul>
      <h4>For California Residents</h4>
      <p>
        In particular, we will be collecting the following categories of
        personal information. We have not collected any information from
        consumers within the last twelve (12) months.
      </p>
      <table>
        <thead>
          <tr>
            <th width="15%"><strong>Category</strong></th>
            <th width="70%"><strong>Examples</strong></th>
            <th width="15%"><strong>To Be Collected</strong></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>A. Identifiers.</td>
            <td>
              A real name, alias, postal address, unique personal identifier,
              online identifier, Internet Protocol address, email address,
              account name, Social Security number, driver's license number,
              passport number, or other similar identifiers.
            </td>
            <td>YES</td>
          </tr>
          <tr>
            <td>
              B. Personal information categories listed in the California
              Customer Records statute (Cal. Civ. Code § 1798.80(e)).
            </td>
            <td>
              <p>
                A name, signature, Social Security number, physical
                characteristics or description, address, telephone number,
                passport number, driver's license or state identification card
                number, insurance policy number, education, employment,
                employment history, bank account number, credit card number,
                debit card number, or any other financial information, medical
                information, or health insurance information.
              </p>
              <p>
                Some personal information included in this category may overlap
                with other categories.
              </p>
            </td>
            <td>YES</td>
          </tr>
          <tr>
            <td>
              C. Protected classification characteristics under California or
              federal law.
            </td>
            <td>
              Age (40 years or older), race, color, ancestry, national origin,
              citizenship, religion or creed, marital status, medical condition,
              physical or mental disability, sex (including gender, gender
              identity, gender expression, pregnancy or childbirth and related
              medical conditions), sexual orientation, veteran or military
              status, genetic information (including familial genetic
              information).
            </td>
            <td>YES</td>
          </tr>
          <tr>
            <td>D. Commercial information.</td>
            <td>
              Records of personal property, products or services purchased,
              obtained, or considered, or other purchasing or consuming
              histories or tendencies.
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>E. Biometric information.</td>
            <td>
              Genetic, physiological, behavioral, and biological
              characteristics, or activity patterns used to extract a template
              or other identifier or identifying information, such as,
              fingerprints, faceprints, and voiceprints, iris or retina scans,
              keystroke, gait, or other physical patterns, and sleep, health, or
              exercise data.
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>F. Internet or other similar network activity.</td>
            <td>
              Browsing history, search history, information on a consumer's
              interaction with a website, application, or advertisement.
            </td>
            <td>YES</td>
          </tr>
          <tr>
            <td>G. Geolocation data.</td>
            <td>Physical location or movements.</td>
            <td>NO</td>
          </tr>
          <tr>
            <td>H. Sensory data.</td>
            <td>
              Audio, electronic, visual, thermal, olfactory, or similar
              information.
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>I. Professional or employment-related information.</td>
            <td>Current or past job history or performance evaluations.</td>
            <td>YES</td>
          </tr>
          <tr>
            <td>
              J. Non-public education information (per the Family Educational
              Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part
              99)).
            </td>
            <td>
              Education records directly related to a student maintained by an
              educational institution or party acting on its behalf, such as
              grades, transcripts, class lists, student schedules, student
              identification codes, student financial information, or student
              disciplinary records.
            </td>
            <td>YES</td>
          </tr>
          <tr>
            <td>K. Inferences drawn from other personal information.</td>
            <td>
              Profile reflecting a person's preferences, characteristics,
              psychological trends, predispositions, behavior, attitudes,
              intelligence, abilities, and aptitudes.
            </td>
            <td>NO</td>
          </tr>
        </tbody>
      </table>
      <p>
        <em
          >Information We Collect Through Automatic Data Collection
          Technologies</em
        >
      </p>
      <p>
        As you navigate through and interact with our Website, we may use
        automatic data collection technologies to collect certain information
        about your equipment, browsing actions, and patterns, including:
      </p>
      <ul>
        <li>
          Details of your visits to our Website, including traffic data,
          location data, logs, and other communication data and the resources
          that you access and use on the Website.
        </li>
        <li>
          Information about your computer and internet connection, including
          your IP address, operating system, and browser type.
        </li>
      </ul>
      <p>
        The information we collect automatically includes personal information.
        It helps us to improve our Website and to deliver a better and more
        personalized service, including by enabling us to:
      </p>
      <ul>
        <li>Estimate our audience size and usage patterns.</li>
        <li>
          Store information about your preferences, allowing us to customize our
          Website according to your individual interests.
        </li>
        <li>Recognize you when you return to our Website.</li>
      </ul>
      <p>
        The technologies we use for this automatic data collection may include:
      </p>
      <ul>
        <li>
          <strong>Cookies (or browser cookies).</strong> A cookie is a small
          file placed on the hard drive of your computer. You may refuse to
          accept browser cookies by activating the appropriate setting on your
          browser. However, if you select this setting you may be unable to
          access certain parts of our Website. Unless you have adjusted your
          browser setting so that it will refuse cookies, our system will issue
          cookies when you direct your browser to our Website.
        </li>
        <li>
          <strong>Web Beacons.</strong> Pages of our Website may contain small
          electronic files known as web beacons (also referred to as clear gifs,
          pixel tags, and single-pixel gifs) that permit the Company, for
          example, to count users who have visited those pages or and for other
          related website statistics (for example, recording the popularity of
          certain website content and verifying system and server integrity).
        </li>
      </ul>
      <h3><u>Use of Personal Information</u></h3>
      <p>
        We may use, or disclose the personal information we collect for one or
        more of the following purposes:
      </p>
      <ul>
        <li>To fulfill or meet the reason you provided the information.</li>
        <li>
          To provide, support, personalize, and develop our Website, products,
          and services.
        </li>
        <li>
          To create, maintain, customize, and secure your account with us.
        </li>
        <li>
          To provide you with support and to respond to your inquiries,
          including to investigate and address your concerns and monitor and
          improve our responses.
        </li>
        <li>
          To personalize your Website experience and to deliver content and
          product and service offerings relevant to your interests, including
          targeted offers and ads through our Website, third-party sites, and
          via email or text message (with your consent, where required by law).
        </li>
        <li>
          To help maintain the safety, security, and integrity of our Website,
          products and services, databases and other technology assets, and
          business.
        </li>
        <li>
          To respond to law enforcement requests and as required by applicable
          law, court order, or governmental regulations.
        </li>
        <li>
          As described to you when collecting your personal information or as
          otherwise set forth in the applicable laws, including the California
          Consumer Privacy Act (“CCPA”).
        </li>
        <li>
          To evaluate or conduct a merger, divestiture, restructuring,
          reorganization, dissolution, or other sale or transfer of some or all
          of our assets, whether as a going concern or as part of bankruptcy,
          liquidation, or similar proceeding, in which personal information held
          by us about our Website users is among the assets transferred.
        </li>
      </ul>
      <p>
        We will not collect additional categories of personal information or use
        the personal information we collected for materially different,
        unrelated, or incompatible purposes without providing you notice.
      </p>
      <h4><u>Disclosure of Your Information</u></h4>
      <p>
        We may disclose aggregated information about our users, and information
        that does not identify any individual, without restriction.
      </p>
      <p>
        We may disclose personal information that we collect or you provide as
        described in this privacy policy:
      </p>
      <ul>
        <li>To our subsidiaries and affiliates.</li>
        <li>
          To contractors, service providers, and other third parties we use to
          support our business and who are bound by contractual obligations to
          keep personal information confidential and use it only for the
          purposes for which we disclose it to them.
        </li>
        <li>
          To a buyer or other successor in the event of a merger, divestiture,
          restructuring, reorganization, dissolution, or other sale or transfer
          of some or all of DIGU Partner's assets, whether as a going concern or
          as part of bankruptcy, liquidation, or similar proceeding, in which
          personal information held by DIGU about our Website users is among the
          assets transferred.
        </li>
        <li>To fulfill the purpose for which you provide it.</li>
        <li>
          For any other purpose disclosed by us when you provide the
          information.
        </li>
        <li>With your consent.</li>
      </ul>
      <p>We may also disclose your personal information:</p>
      <ul>
        <li>
          To comply with any court order, law, or legal process, including to
          respond to any government or regulatory request.
        </li>
        <li>
          To enforce or apply our terms of use and other agreements, including
          for billing and collection purposes.
        </li>
        <li>
          If we believe disclosure is necessary or appropriate to protect the
          rights, property, or safety of DIGU, our customers, or others.
        </li>
      </ul>
      <h4>
        <u> Choices About How We Use and Disclose Your Information </u>
      </h4>
      <p>
        We strive to provide you with choices regarding the personal information
        you provide to us. We have created mechanisms to provide you with the
        following control over your information:
      </p>
      <ul>
        <li>
          <strong>Tracking Technologies and Advertising.</strong> You can set
          your browser to refuse all or some browser cookies, or to alert you
          when cookies are being sent. If you disable or refuse cookies, please
          note that some parts of this site may then be inaccessible or not
          function properly.
        </li>
      </ul>
      <p>
        We do not control third parties' collection or use of your information
        to serve interest-based advertising. However these third parties may
        provide you with ways to choose not to have your information collected
        or used in this way. You can opt out of receiving targeted ads from
        members of the Network Advertising Initiative ("<strong>NAI</strong>")
        on the NAI's website.
      </p>
      <h4><u>Rights for California Residents</u></h4>
      <p>
        The CCPA provides consumers (California residents) with specific rights
        regarding their personal information. This section describes your CCPA
        rights and explains how to exercise those rights.
      </p>
      <p>
        <strong><em>Right to Know and Data Portability</em></strong>
      </p>
      <p>
        You have the right to request that we disclose certain information to
        you about our collection and use of your personal information over the
        past 12 months (the "right to know"). Once we receive your request and
        confirm your identity (see
        <a href="#exercising-your-rights-to-know-or-delete"
          >Exercising Your Rights to Know or Delete</a
        >), we will disclose to you:
      </p>
      <ul>
        <li>The categories of personal information we collected about you.</li>
        <li>
          The categories of sources for the personal information we collected
          about you.
        </li>
        <li>
          Our business or commercial purpose for collecting or selling that
          personal information.
        </li>
        <li>
          The categories of third parties with whom we share that personal
          information.
        </li>
        <li>
          If we sold or disclosed your personal information for a business
          purpose, two separate lists disclosing:
        </li>
        <ul>
          <li>
            sales, identifying the personal information categories that each
            category of recipient purchased; and
          </li>
          <li>
            disclosures for a business purpose, identifying the personal
            information categories that each category of recipient obtained.
          </li>
        </ul>
        <li>
          The specific pieces of personal information we collected about you
          (also called a data portability request).
        </li>
      </ul>
      <p>
        We do not provide a right to know or data portability disclosure for B2B
        personal information.
      </p>
      <p>
        <strong><em>Right to Delete</em></strong>
      </p>
      <p>
        You have the right to request that we delete any of your personal
        information that we collected from you and retained, subject to certain
        exceptions (the "right to delete"). Once we receive your request and
        confirm your identity (see
        <a href="#exercising-your-rights-to-know-or-delete"
          >Exercising Your Rights to Know or Delete</a
        >), we will review your request to see if an exception allowing us to
        retain the information applies. We may deny your deletion request if
        retaining the information is necessary for us or our service provider(s)
        to:
      </p>
      <ol>
        <li>
          Complete the transaction for which we collected the personal
          information, provide a good or service that you requested, take
          actions reasonably anticipated within the context of our ongoing
          business relationship with you, fulfill the terms of a written
          warranty or product recall conducted in accordance with federal law,
          or otherwise perform our contract with you.
        </li>
        <li>
          Detect security incidents, protect against malicious, deceptive,
          fraudulent, or illegal activity, or prosecute those responsible for
          such activities.
        </li>
        <li>
          Debug products to identify and repair errors that impair existing
          intended functionality.
        </li>
        <li>
          Exercise free speech, ensure the right of another consumer to exercise
          their free speech rights, or exercise another right provided for by
          law.
        </li>
        <li>
          Comply with the California Electronic Communications Privacy Act (Cal.
          Penal Code § 1546 et. seq.).
        </li>
        <li>
          Engage in public or peer-reviewed scientific, historical, or
          statistical research in the public interest that adheres to all other
          applicable ethics and privacy laws, when the information's deletion
          may likely render impossible or seriously impair the research's
          achievement, if you previously provided informed consent.
        </li>
        <li>
          Enable solely internal uses that are reasonably aligned with consumer
          expectations based on your relationship with us.
        </li>
        <li>Comply with a legal obligation.</li>
        <li>
          Make other internal and lawful uses of that information that are
          compatible with the context in which you provided it.
        </li>
      </ol>
      <p>
        We will delete or deidentify personal information not subject to one of
        these exceptions from our records and will direct our service providers
        to take similar action.
      </p>
      <p>
        We do not provide these deletion rights for B2B personal information.
      </p>
      <p id="exercising-your-rights-to-know-or-delete">
        <strong><em>Exercising Your Rights to Know or Delete</em></strong>
      </p>
      <p>
        To exercise your rights to know or delete described above, please submit
        a request by either:
      </p>
      <ul>
        <li>Emailing us at info@digu.one.</li>
      </ul>
      <p>
        Only you, or someone legally authorized to act on your behalf, may make
        a request to know or delete related to your personal information.
      </p>
      <p>
        You may also make a request to know or delete on behalf of your child by
        emailing the email address above.
      </p>
      <p>
        You may only submit a request to know twice within a 12-month period.
        Your request to know or delete must:
      </p>
      <ul>
        <li>
          Provide sufficient information that allows us to reasonably verify you
          are the person about whom we collected personal information or an
          authorized representative.
        </li>
        <li>
          Describe your request with sufficient detail that allows us to
          properly understand, evaluate, and respond to it.
        </li>
      </ul>
      <p>
        We cannot respond to your request or provide you with personal
        information if we cannot verify your identity or authority to make the
        request and confirm the personal information relates to you.
      </p>
      <p>
        You do not need to create an account with us to submit a request to know
        or delete.
      </p>
      <p>
        We will only use personal information provided in the request to verify
        the requestor's identity or authority to make it.
      </p>
      <p>
        <strong><em>Response Timing and Format</em></strong>
      </p>
      <p>
        We will confirm receipt of your request within ten (10) business days.
        If you do not receive confirmation within the 10-day timeframe, please
        email
        <a href="mailto:info@digu.one">info@digu.one</a>
      </p>
      <p>
        We endeavor to substantively respond to a verifiable consumer request
        within forty-five (45) days of its receipt. If we require more time (up
        to another 45 days), we will inform you of the reason and extension
        period in writing.
      </p>
      <p>
        If you have an account with us, we will deliver our written response to
        that account. If you do not have an account with us, we will deliver our
        written response by mail or electronically, at your option.
      </p>
      <p>
        Any disclosures we provide will only cover the 12-month period preceding
        our receipt of your request. The response we provide will also explain
        the reasons we cannot comply with a request, if applicable. For data
        portability requests, we will select a format to provide your personal
        information that is readily useable and should allow you to transmit the
        information from one entity to another entity without hindrance.
      </p>
      <p>
        We do not charge a fee to process or respond to your verifiable consumer
        request unless it is excessive, repetitive, or manifestly unfounded. If
        we determine that the request warrants a fee, we will tell you why we
        made that decision and provide you with a cost estimate before
        completing your request.
      </p>
      <p>
        <strong
          ><em>Personal Information Sales Opt-Out and Opt-In Rights</em></strong
        >
      </p>
      <p>
        If you are age 16 or older, you have the right to direct us to not sell
        your personal information at any time (the "right to opt-out"). However,
        we do not sell your personal information so this right does not apply.
      </p>
      <p>
        <strong><em>Non-Discrimination</em></strong>
      </p>
      <p>
        We will not discriminate against you for exercising any of your CCPA
        rights. Unless permitted by the CCPA, we will not:
      </p>
      <ul>
        <li>Deny you goods or services.</li>
        <li>
          Charge you different prices or rates for goods or services, including
          through granting discounts or other benefits, or imposing penalties.
        </li>
        <li>Provide you a different level or quality of goods or services.</li>
        <li>
          Suggest that you may receive a different price or rate for goods or
          services or a different level or quality of goods or services.
        </li>
      </ul>
      <h4 id="changes-to-our-privacy-policy">
        <u>Changes to Our Privacy Policy</u>
      </h4>
      <p>
        We reserve the right to amend this privacy policy at our discretion and
        at any time. When we make changes to this privacy policy, we will post
        the updated notice on the Website and update the notice's effective
        date.
        <strong>
          Your continued use of our Website following the posting of changes
          constitutes your acceptance of such changes.
        </strong>
      </p>
      <h4>
        <u>Contact Information</u>
      </h4>
      <p>
        If you have any questions or comments about this notice, the ways in
        which DIGU collects and uses your information described here, your
        choices and rights regarding such use, or wish to exercise your rights
        under California law, please do not hesitate to contact us at:
      </p>
      <div style="margin-left: 2rem">
        <p>
          <strong>Phone</strong>:
          <a href="tel:+1 (888) 744-1094">+1 (888) 744-1094</a>
        </p>
        <p>
          <strong>Website</strong>:
          <a href="https://www.digu.one/">https://www.digu.one/</a>
        </p>
        <p>
          <strong>Email</strong>:
          <a href="mailto:info@digu.one">info@digu.one</a>
        </p>
        <p>
          <strong>Postal Address: </strong>
        </p>
        <p style="margin-left: 2rem">
          166 Geary Str STE 1500 #1230,<br />
          San Francisco, CA 94108, USA
        </p>
      </div>
    </section>
  </section>
</template>
<script setup>
import { onMounted } from 'vue';
import HeroSection from '@/components/partials/HeroSection.vue';

onMounted(() => {
  document.getElementById('loaderSection').classList.add('d-none');
});
</script>
<style scoped>
.content {
  max-width: 1000px;
  margin-inline: 1.5rem;
  text-align: justify;
}

@media only screen and (min-width: 1024px) {
  .content {
    margin-inline: auto;
    text-align: justify;
  }
}
p,
ul,
ol {
  margin-bottom: 1rem;
}

ul,
ol {
  margin-left: 2rem;
}
table {
  width: 100%;
  font-size: 0.8rem;
  border: 1px solid black;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

@media only screen and (min-width: 768px) {
  table {
    font-size: initial;
  }
}

th {
  text-align: center;
  border: 1px solid black;
  border-collapse: collapse;
}

td {
  vertical-align: top;
  justify-items: start;
  border: 1px solid black;
  border-collapse: collapse;
  padding: 0.5rem;
  text-align: left;
}

li {
  text-align: left;
  margin-bottom: 0.85rem;
}
</style>
