<template>
  <section class="section--main">
    <v-row class="section__row">
      <v-col
        class="section__col"
        cols="12"
        lg="6"
        v-if="mainSection.featureImage"
      >
        <v-img
          eager
          :src="require(`@/assets/img/${mainSection.featureImage}`)"
          cover
          class="feature-image"
        ></v-img
      ></v-col>
      <v-col cols="12" :lg="mainSection.featureImage ? 6 : 12">
        <p class="main__description m600 my-7">
          {{ mainSection.mainSectionParagraph }}
        </p>
        <p class="secondary__description m400">
          {{ mainSection.mainSectionSecondaryParagraph }}
        </p>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  name: "MainSection",
  props: {
    mainSection: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.section--main {
  padding-block: 5rem;
  background-color: var(--light-1);
  padding-inline: 2rem;
}

@media only screen and (min-width: 1280px) {
  .section--main {
    padding-inline: initial;
  }
}

.section__row {
  max-width: 1400px;
  margin-inline: auto;
}

.feature-image {
  border-radius: 2rem;
}

.main__description,
.secondary__description {
  text-align: justify;
  line-height: 150%;
  letter-spacing: 0px;
}

.main__description {
  color: var(--accent-red);
  font-size: 1.1rem;
  margin-top: 2rem;
}

@media only screen and (min-width: 1280px) {
  .main__description {
    margin-top: initial;
  }
}

.secondary__description {
  font-size: 1rem;
}
</style>
