<script setup>
import DeveloperCard from '@/components/partials/DeveloperCard.vue';
import { Carousel, Slide, Pagination } from 'vue3-carousel';
import { ref } from 'vue';

const developers = ref([
  {
    name: 'Ujjwal Raja Maharjan',
    title: 'Senior Full Stack Engineer',
    hasImage: true,
    message: `The overall experience from securing my job and onboarding has been exceptionally positive.
DIGU's meticulous preparation and seamless processes not only met but surpassed my
initial expectations. I am fully satisfied and enthusiastic about embarking on this new journey
with the company, confident that it sets the tone for a successful collaboration.`,
  },
  
  {
    name: 'Rubin Maharjan',
    title: 'Senior UI/UX Designer',
    hasImage: true,
    message: `I'm truly thankful to DIGU for helping me land full-time employment with a US-based company. Their recruitment process, which was both meticulous and smooth, opened the door for me to work with a diverse and talented global pool. I am grateful to the DIGU team for their unwavering commitment to advancing careers and their efforts in connecting professionals with opportunities worldwide. My career has been significantly transformed through their impact. As I move forward in my professional journey with the skills and confidence they've helped me develop, I look forward to the exciting opportunities ahead.`,
  },
  {
    name:'Shirish Parajuli',
    title: 'Frontend Developer',
    hasImage: true,
    message: `I’m incredibly grateful to DIGU for helping me achieve a major career milestone—a full-time frontend developer position at a US-based company. Despite trying every platform, I struggled to move forward in the hiring process. DIGU’s user-friendly and organized recruitment made all the difference. From my LinkedIn submission to overcoming obstacles, they guided me to success. Now, I work in a cutting-edge environment with skilled colleagues, boosting my skills and expanding my perspective.`,
  },
]);

const carouselModel = ref(0);
</script>
<template>
  <h3 class="dms700 title">
    What Do Our <span class="highlight">Talents</span> Say?
  </h3>
  <section class="developer-stories">
    <v-row justify="center" align="center" class="desktop-stories">
      <v-col
        v-for="(developer, index) in developers"
        :key="index"
        cols="12"
        xs="12"
        md="4"
        class="d-flex justify-center"
      >
        <DeveloperCard
          :name="developer.name"
          :title="developer.title"
          :message="developer.message"
          :hasImage="developer.hasImage"
        ></DeveloperCard>
      </v-col>
    </v-row>
  </section>
  <Carousel
    class="mobile-stories"
    v-model="carouselModel"
    :wrap-around="true"
    :items-to-show="1"
  >
    <Slide
      v-for="(developer, index) in developers"
      :key="index"
      class="mobile-stories__slide"
    >
      <div class="pa-3">
        <DeveloperCard
          :name="developer.name"
          :title="developer.title"
          :message="developer.message"
          :hasImage="developer.hasImage"
        ></DeveloperCard>
      </div>
    </Slide>
    <template #addons>
      <Pagination style="background-color: var(--light-1); margin-top: 0px; padding-bottom:1rem;" />
    </template>
  </Carousel>
</template>
<style scoped>
.developer-stories {
  background-color: var(--light-1);
  
  
}

.title {
  background-color: var(--light-1);
  padding-block: 2rem;
  line-height: 1.75rem;
  font-size: 1.5rem;
}

.desktop-stories {
  display: none;
  width: 95%;
  max-width: 1400px;
  margin-inline: auto;
}

.mobile-stories__slide {
  background-color: var(--light-1) !important;
}

@media only screen and (min-width: 1280px) {
  .desktop-stories {
    display: flex;
  }

  .mobile-stories {
    display: none;
  }
}

.highlight {
  color: var(--primary-color);
}
</style>
