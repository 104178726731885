<template>
  <section class="section--communication">
    <div class="section__container">
      <v-row class="section__row">
        <v-col class="section__col order-last order-sm-first" cols="12">
          <h2 class="section__title dms600">Level 2: Communicator Badge</h2>

          <p class="section__description m500">
            The Level 2 badge focuses on enhancing your communication and
            interview skills. Completing our mandatory and free Interview
            Preparation Workshop is essential to earn this badge, reflecting
            your readiness to engage effectively in professional interactions
            and high-stakes scenarios.
          </p>
        </v-col>
      </v-row>
      <v-row class="section__row">
        <v-col cols="12" md="12" lg="6">
          <keep-alive>
            <v-img
              :src="require('@/assets/img/communicator.webp')"
              width="300px"
              class="mx-auto d-lg-none"
            >
            </v-img>
          </keep-alive>
          <p class="dms500 text-left mb-5" style="font-size: 1.5rem">
            Verified Communicator
          </p>
          <p class="m400 text-left panel__paragraph">
            Validates your excellent communication skills, crucial for effective
            collaboration and client engagement. This badge is awarded to
            professionals who excel in clearly and effectively conveying ideas,
            both in writing and speech. To earn this Level 2 badge, you must
            complete our mandatory Interview Preparation Workshop. This training
            ensures you’re fully equipped to excel in interviews and communicate
            with confidence in high-stakes situations.
          </p>
        </v-col>
        <v-col cols="6" class="d-none d-lg-block">
          <keep-alive>
            <v-img
              :src="require('@/assets/img/communicator.webp')"
              width="300px"
              class="mx-auto"
            >
            </v-img></keep-alive
        ></v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CommunicationReadySection',
};
</script>

<style scoped>
.section--communication {
  background-color: var(--secondary-color-super-light);
  padding-inline: 2rem;
  padding-bottom: 10rem;
  padding-top: 5rem !important;
  background-image: url('@/assets/img/developer-hiring-bg.svg');

  background-size: 100vw;
  background-position: bottom;
}

@media only screen and (min-width: 1280px) {
  .section--communication {
    margin-inline: initial;
  }

  .section__col:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

@media only screen and (min-width: 1921px) {
  .section--communication {
    padding-bottom: 20rem;
  }
}

.section__title {
  text-align: left;
  margin-bottom: 0.75rem;
  width: 100%;
  color: var(--accent-red);
  font-size: 40px !important;
}

@media only screen and (min-width: 1200px) {
  .section__title {
    font-size: 2rem;
  }
}

.section__row {
  max-width: 1400px;
  margin-inline: auto;
}

.section__description {
  text-align: left;
  margin-bottom: 1.5rem;
}

.m-img {
  width: 100%;
  max-width: 25rem;
  margin-block: 2rem;
}

.section__img {
  max-height: 25rem;
}
</style>
