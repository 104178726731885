<template>
  <section class="section--coder pt-6">
    <div class="section__container">
      <v-row class="section__row">
        <v-col class="section__col" cols="12" xs="12" sm="12" md="12" lg="12">
          <h2 class="section__title dms700">Level 1: Core Skills Badges</h2>
          <p class="section__description m500">
            Our Level 1 badges highlight your expertise in specific technical
            and creative skills. These badges showcase your proven abilities in
            key areas such as coding, project management, and design,
            establishing you as a capable and skilled professional.
          </p>
        </v-col>
      </v-row>
      <v-row class="section__row">
        <v-col cols="12" md="12" lg="6">
          <v-expansion-panels v-model="currentPanel" variant="accordion">
            <v-expansion-panel
              mandatory
              v-for="(item, index) in panels"
              :key="index"
              :value="item.value"
              elevation="0"
              class="panel"
            >
              <v-expansion-panel-title>
                <p class="dms500" style="font-size: 1.1rem">{{ item.title }}</p>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <keep-alive>
                  <v-img
                    :src="getImageUrl(item.panelImage)"
                    width="300px"
                    class="mx-auto mb-15 d-lg-none"
                  >
                  </v-img>
                </keep-alive>
                <p class="m400 text-left panel__paragraph">
                  {{ item.paragraph }}
                </p>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="6" class="d-none d-lg-block">
          <keep-alive>
            <v-img
              :src="getDynamicUrl()"
              width="300px"
              class="mx-auto"
              v-if="currentPanel !== undefined"
            >
            </v-img> </keep-alive
        ></v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CertifiedCoderSection',
  methods: {
    getImageUrl(name) {
      return require(`@/assets/img/${name}`);
    },
    getDynamicUrl() {
      const imageName = this.panels[this.currentPanel].panelImage;
      return require(`@/assets/img/${imageName}`);
    },
  },
  watch: {
    currentPanel(newVal, oldVal) {
      if (newVal === undefined) {
        this.currentPanel = oldVal;
      }
    },
  },
  data: () => ({
    currentPanel: 0,
    panels: [
      {
        panelImage: 'coder_level_1.webp',
        title: 'Verified Coder',
        value: 0,
        paragraph:
          'Recognizes your outstanding programming and software development abilities. This badge highlights your mastery of various coding languages and a proven history of successful projects, establishing you as a highly sought-after tech professional.',
      },
      {
        panelImage: 'project_leader.webp',
        value: 1,
        title: 'Verified Project Manager',
        paragraph:
          'Highlights your exceptional project management and leadership skills. Earning this badge showcases your expertise in driving complex projects to completion, leading teams effectively, and consistently delivering results on time and within budget.',
      },
      {
        panelImage: 'creative_specialist.webp',
        value: 2,
        title: 'Verified Creative Specialist',
        paragraph:
          'Celebrates your superior skills in creative fields, such as graphic design and UI/UX design. This badge signifies your ability to turn innovative ideas into visually compelling solutions, enhancing user experiences and delivering high-impact designs.',
      },
    ],
  }),
};
</script>

<style scoped>
.section--coder {
  background-color: var(--accent-purple-lighten-2);
  padding-bottom: 12rem;
  background-image: url('@/assets/img/wave-vector-light-blue.svg');
  background-size: 100vw;
  background-position: bottom;
}

@media only screen and (min-width: 700px) {
  .section__col:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

@media only screen and (min-width: 1921px) {
  .section--coder {
    padding-bottom: 20rem;
  }
}

.section__title {
  text-align: left;
  margin-bottom: 0.75rem;
  width: 100%;
  color: var(--accent-red);
  font-size: 40px !important;
}

@media only screen and (min-width: 1280px) {
  .section__title {
    font-size: 2rem;
  }
}

.section__description {
  text-align: center;
  margin-bottom: 1.5rem;
  text-align: left;
}

.section__img {
  max-width: 25rem;
}

.m-img {
  width: 100%;
  max-width: 25rem;
  margin-block: 2rem;
}

.panel {
  background: none !important;
}

.panel .v-expansion-panel-title__overlay {
  background: none;
}

.panel__paragraph {
  font-size: 0.9rem;
}
</style>
