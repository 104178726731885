<template>
  <div class="events-list">
    <HeroSection
      :title="heroDetails.title"
      :showWhiteWave="heroDetails.showWhiteWave"
    ></HeroSection>
    <div class="section__container">
      <div class="loader" v-if="UIController.loader.contentLoading">
        <v-progress-circular indeterminate></v-progress-circular>
        <p class="mt-5">
          Hold On! while we fetch upcoming Trainings and Certifications for you.
        </p>
      </div>
      <div v-else>
        <v-card elevation="0" class="error-card" v-if="!hasJobOpenings">
          <v-card-title class="error-card__title text-left dms700"
            >There are no Upcoming Trainings and Certifications</v-card-title
          >
          <v-card-text>
            <h3 class="error-card__text-title text-left m500">
              Looks like there are no openings available at the moment
            </h3>
            <p class="error-card__text text-left m500">
              Why don't you check back in a day or two to see what we have got
              for you.
            </p>
          </v-card-text>
        </v-card>
        <div v-else>
          <v-card
            elevation="0"
            class="event-card"
            v-for="event in upcomingEvents"
            :key="event.id"
          >
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="3" md="4" lg="2">
                  <v-img
                    :src="event.event_banner"
                    cover
                    width="100%"
                    class="event-image"
                    :lazy-src="require('@/assets/img/lazy-img.jpg')"
                    :aspect-ratio="1 / 1"
                  ></v-img>
                </v-col>
                <v-col cols="12" sm="9" md="8" lg="10">
                  <p class="event-card__title text-left dms500">
                    <a
                      :href="`/trainings-and-certifications/${event.id}`"
                      class="event-card__link"
                      >{{ event.name }}</a
                    >
                  </p>
                  <p class="event-card__date text-left dms600">
                    {{ eventDateComputed(event.event_date_original) }}
                  </p>
                  <p class="event-card__description text-left mt-2">
                    {{ event.description }}
                  </p>
                  <div class="d-flex justify-start mt-2">
                    <a
                      :href="`/trainings-and-certifications/${event.id}`"
                      class="job-card__link link--footer"
                      >Read More ></a
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
    <v-snackbar
      v-model="UIController.snackbar.active"
      :location="UIController.snackbar.location"
      :text="'UIController.snackbar.text'"
      :color="UIController.snackbar.color"
    >
      {{ UIController.snackbar.text }}</v-snackbar
    >
  </div>
</template>
<script setup>
import { computed, onMounted, reactive, ref } from 'vue';
import HeroSection from '@/components/partials/HeroSection.vue';

import trainingsService from '@/services/API/trainingsService';

// Global Variables
const heroDetails = ref({
  title: 'Upcoming Trainings and Certifications Events',
  showWhiteWave: true,
});

const UIController = reactive({
  loader: {
    contentLoading: true,
  },
  snackbar: {
    active: false,
    location: 'bottom right',
    text: '',
    color: 'danger',
  },
});

// fetched variables
let upcomingEvents = reactive([]);

// On Created Function
onMounted(async () => {
  document.getElementById('loaderSection').classList.add('d-none');
  await trainingsService
    .listAllTrainings()
    .then((response) => {
      // if error in response throw a response
      // else show the details
      UIController.snackbar.text =
        'Trainings and Certifications Events fetched successfully';
      UIController.snackbar.color = 'success';
      UIController.snackbar.active = true;
      upcomingEvents = response.data;
      UIController.loader.contentLoading = false;
    })
    .catch(() => {
      UIController.snackbar.text =
        'Could not fetch events and certifications events';
      UIController.snackbar.color = 'error';
      UIController.snackbar.active = true;
    });
});

// Methods
const eventDateComputed = (date) => {
  return new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'full',
    timeStyle: 'short',
    hour12: true,
  }).format(new Date(parseInt(date)));
};

// Computed properties
const hasJobOpenings = computed(() => {
  return upcomingEvents.length > 0 ? true : false;
});
</script>
<style scoped>
.error-card {
  background-color: var(--light-1);
  max-width: 500px;
  margin-inline: auto;
  margin-block: 2rem;
}

.loader {
  padding-block: 5rem;
}

.event-card {
  margin-block: 2rem;
  border-bottom: 1px solid var(--gray-2);
  border-radius: 0px;
}

.event-card:last-child {
  border: none;
}

.event-card__title {
  font-size: 1.5rem;
  line-height: 1.2;
  margin-block: 0rem 0.25rem;
  color: var(--primary-color-lighten);
}

@media only screen and (min-width: 1024px) {
  .event-card__title {
    font-size: 2rem;
  }
}

.event-image {
  border-radius: 0.4rem;
  aspect-ratio: 1/1;
  /* aspect-ratio: 16/9; */
}

@media only screen and (min-width: 1024px) {
  .event-image {
    aspect-ratio: 1/1;
  }
}

.event-card__date {
  font-size: 0.9rem;
}

.event-card__link {
  text-decoration: none;
  color: inherit;
}

.link--footer:hover {
  text-decoration: underline;
}

.event-card__tags {
  margin-block: 0.5rem;
}

.event-card__skill-chip {
  margin-right: 0.5rem;
}

.event-card__description {
  display: -moz-box;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 0.9rem;
  line-height: 1.5rem;
  max-height: 3.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
</style>
