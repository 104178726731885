<template>
  <div class="customers" id="view">
    <hero-section :title="title" :subTitle="subTitle" />
    <stories-section />
  </div>
</template>

<script>
// Global Components
import HeroSection from "@/components/partials/HeroSection.vue";

// Local Components
import StoriesSection from "./Sections/StoriesSection.vue";
export default {
  name: "CustomersVue",
  components: {
    "hero-section": HeroSection,
    "stories-section": StoriesSection,
  },
  data: () => ({
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum rutrum lacus",
    subTitle: "Customer Stories",
  }),
  created() {
    document.getElementById("loaderSection").classList.add("d-none");
  },
};
</script>
