<template>
  <div
    class="story-card"
    :class="slug"
    @mouseover="mouseOver"
    @mouseout="mouseLeave"
  >
    <span class="tap-indicator d-sm-block d-lg-none"
      ><v-icon color="#fff">mdi-gesture-tap</v-icon></span
    >
    <p
      class="story-card__accent"
      :class="{
        'slide-out-tl': UIController.animations.exitCornerAccent,
        'slide-in-tl': UIController.animations.enterCornerAccent,
      }"
    ></p>
    <div class="card__details">
      <p
        class="card__subtitle o600"
        :class="{
          'hide-subtitle': UIController.displays.hideSubtitle,
        }"
      >
        {{ subTitle }}
      </p>
      <h3
        class="card__title dms700"
        :class="{
          'center-title': UIController.displays.centerTitle,
        }"
      >
        {{ title }}
      </h3>
    </div>
    <div
      class="card__link-container"
      :class="{
        'slide-out-bottom': UIController.animations.exitBottomAccent,
        'slide-in-bottom': UIController.animations.enterBottomAccent,
      }"
    >
      <a :href="`/customers/${slug}`" class="link"
        ><span class="m600">Read More</span>
        <v-icon>mdi-chevron-right</v-icon></a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "StoryCard",
  props: {
    id: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    bg: {
      type: String,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    UIController: {
      animations: {
        exitCornerAccent: false,
        enterCornerAccent: true,
        exitBottomAccent: true,
        enterBottomAccent: false,
      },
      displays: {
        hideSubtitle: false,
        centerTitle: false,
      },
    },
  }),
  methods: {
    mouseOver() {
      this.UIController.animations.exitCornerAccent = true;
      this.UIController.animations.enterBottomAccent = true;
      this.UIController.animations.enterCornerAccent = false;
      this.UIController.animations.exitBottomAccent = false;
      this.UIController.displays.hideSubtitle = true;
      this.UIController.displays.centerTitle = true;
    },
    mouseLeave() {
      this.UIController.animations.exitCornerAccent = false;
      this.UIController.animations.enterCornerAccent = true;
      this.UIController.animations.exitBottomAccent = true;
      this.UIController.animations.enterBottomAccent = false;
      this.UIController.displays.hideSubtitle = false;
      this.UIController.displays.centerTitle = false;
    },
  },
};
</script>

<style scoped>
.story-card {
  position: relative;
  width: 100%;
  max-width: 575px;
  min-height: 375px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: background 500ms ease-in-out;
  overflow: hidden;
  background-color: var(--white-1);
}

.story-card:hover {
  background-color: var(--primary-color-darken) !important;
  background-size: cover;
  background-blend-mode: multiply;
  /* background-blend-mode: overlay; */
}

.card__details {
  text-align: left;
  width: 90%;
}

.card__subtitle {
  color: var(--accent-red);
  font-size: 1.25rem;
  transition: opacity 200ms ease-in-out;
}

.card__title {
  font-size: 1.75rem;
  letter-spacing: 0px;
  line-height: 120%;
  transition: all 100ms ease-in-out;
}

.card__link-container {
  position: absolute;
  bottom: -1px;
  background-image: url("@/assets/img/story-card-hover-bg.svg");
  width: 100%;
  height: 122px;
  background-size: 101%;
  background-position-y: bottom;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card__link-container .link {
  height: max-content;
  text-decoration: none;
  color: var(--primary-color-darken);
  display: flex;
  align-items: center;
  padding-top: 2rem;
}

@media only screen and (min-width: 1280px) {
  .card__link-container .link {
    padding-top: initial;
    align-items: flex-end;
  }
}

.story-card__accent {
  display: block;
  height: 100px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("@/assets/img/story-card-corner.svg");
  background-size: contain;
}

.hide-subtitle {
  display: none;
}

.center-title {
  color: var(--light-1);
  text-align: center;
  font-size: 1.9rem;
}

.tap-indicator {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--primary-color-lighten);
  padding: 0.4rem;
  border-radius: 30px;
  z-index: 10000;
}

/* Animations */

/* Slide out WHen Mouse Over: Top Left */
.slide-out-tl {
  -webkit-animation: slide-out-tl 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: slide-out-tl 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-7-24 15:1:55
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-tl
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-tl {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px) translateX(-1000px);
    transform: translateY(-1000px) translateX(-1000px);
    opacity: 0;
  }
}
@keyframes slide-out-tl {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px) translateX(-1000px);
    transform: translateY(-1000px) translateX(-1000px);
    opacity: 0;
  }
}

/* Slide in when mouse  */
.slide-in-tl {
  -webkit-animation: slide-in-tl 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-tl 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-7-24 15:6:49
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-tl
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-tl {
  0% {
    -webkit-transform: translateY(-1000px) translateX(-1000px);
    transform: translateY(-1000px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-tl {
  0% {
    -webkit-transform: translateY(-1000px) translateX(-1000px);
    transform: translateY(-1000px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
}

/* Slide in from bottom */
.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-7-24 15:10:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-out-bottom {
  -webkit-animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-7-24 15:13:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}
</style>
