


<template>
  <global-header />
  <div id="loaderSection" class="">
    <div class="section__content"></div>
    <v-progress-circular
      indeterminate
      :size="150"
      :width="7"
      color="#2ea3f2"
      class="main-loader"
    ></v-progress-circular>
  </div>
  <router-view />
  <global-footer />
</template>
<script>
import GlobalHeader from '@/components/partials/GlobalHeader.vue';
import GlobalFooterVue from '@/components/partials/GlobalFooter.vue';
import '@/assets/css/fonts.css';
export default {
  name: 'SmartworksTechnologies',
  components: {
    'global-header': GlobalHeader,
    'global-footer': GlobalFooterVue,
  },
};
</script>

<style>
.atcb-initialized div#atcb-reference {
  display: none;
}
#app {
  box-sizing: border-box;
  font-family: 'Manrope Medium', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  scroll-behavior: smooth;
}

#loaderSection {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-1);
}

.carousel__prev,
.carousel__next {
  height: 100% !important;
  width: 2rem !important;
}

/* overrides */
.carousel__prev .carousel__icon,
.carousel__next .carousel__icon {
  color: white;
}

@media only screen and (min-width: 1280px) {
  .carousel__next {
    margin-right: 1rem !important;
  }
}

/* Mobile Carousel Styles */
.m-carousel__slide {
  height: 100%;
}

.m-carousel-card {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding-inline: 0.6rem !important;
}

.m-carousel__image {
  border-radius: 0.8rem !important;
  max-height: 250px;
  width: 100%;
}

.m-carousel__title {
  width: 95%;
  font-size: 1.75rem;
  line-height: 120%;
  margin-top: 1rem;
  text-align: left;
  text-transform: capitalize;
}

.m-carousel__description {
  width: 95%;
  text-align: left;
}

@media only screen and (min-width: 1023px) {
  .m-carousel__title {
    font-size: 1.75rem;
    line-height: 120%;
    margin-top: 1rem;
  }

  .m-carousel__subtitle {
    font-size: 1.25rem;
    margin-top: 1rem;
    color: var(--accent-red);
  }
}

.m-carousel__subtitle {
  font-size: 1.25rem;
  margin-top: 1rem;
  color: var(--accent-red);
  text-align: left;
  display: block;
  text-transform: capitalize;
}

v-list-item-text > div > div > button > span.v-expansion-panel-title__overlay {
  display: none !important;
}

v-list-item-text > div > div > div.v-expansion-panel-text.faq__answer > div {
  padding-inline: 0px !important;
}

v-list-item-text > div > div > button {
  padding-block: 0px !important;
}

.section__container {
  width: 85vw;
  max-width: 1280px;
  margin-inline: auto;
}

.section__container-2 {
  width: 85%;
  max-width: 1280px;
  margin-inline: auto;
}

.sub-section__container {
  width: 85%;
  margin-inline: auto;
}

@media only screen and (min-width: 1024px) {
  .sub-section__container {
    width: 42.5vw;
    max-width: 600px;
    margin-inline: auto;
  }

  .sub-section__container--left {
    margin-inline: initial;
    margin-left: auto;
  }

  .sub-section__container--right {
    margin-inline: initial;
    margin-right: auto;
  }
}

/* utils */
.section__subtitle {
  font-size: 1.25rem !important;
  text-transform: capitalize;
}

@media only screen and (min-width: 700px) {
  .section__subtitle {
    font-size: 1.5rem !important;
  }
}

@media only screen and (min-width: 1200px) {
  .section__subtitle {
    font-size: 1.75rem !important;
  }
}

.section__subtitle-2 {
  font-size: 1rem !important;
}

@media only screen and (min-width: 700px) {
  .section__subtitle-2 {
    font-size: 1.25rem !important;
  }
}

@media only screen and (min-width: 1024px) {
  .section__subtitle-2 {
    font-size: 1.5rem !important;
  }
}

/* Title 1 */
.section__title {
  font-size: 1.8rem !important;
  line-height: 110%;
  text-transform: capitalize;
}

@media only screen and (min-width: 700px) {
  .section__title {
    font-size: 2.5rem !important;
  }
}

@media only screen and (min-width: 1200px) {
  .section__title {
    font-size: 3.5rem !important;
  }
}

.section__title-2 {
  font-size: 1.25rem !important;
  line-height: 110%;
}

@media only screen and (min-width: 700px) {
  .section__title-2 {
    font-size: 1.5rem !important;
  }
}

@media only screen and (min-width: 1024px) {
  .section__title-2 {
    font-size: 1.75rem !important;
  }
}

.section__description {
  font-size: 1rem !important;
  line-height: 150%;
}

@media only screen and (min-width: 700px) {
  .section__description {
    font-size: 1.2rem !important;
  }
}

@media only screen and (min-width: 1200px) {
  .section__description {
    font-size: 1.2rem !important;
  }
}

.section__description-2 {
  font-size: 1rem !important;
  line-height: 150%;
}

@media only screen and (min-width: 700px) {
  .section__description-2 {
    font-size: 1.1rem !important;
  }
}

@media only screen and (min-width: 1200px) {
  .section__description-2 {
    font-size: 1.1rem !important;
  }
}

section.section--carousel > div > div > div > div.v-window__controls {
  padding-inline: 0px;
}

/* Special Sections */
.title--special {
  font-size: 1.5rem;
}

.title--special .title__text {
  line-height: 120%;
}

.title__dot {
  color: #2ea3f2;
  font-size: 1rem;
  margin-inline: 0.5rem;
}

@media only screen and (min-width: 800px) {
  .title--special {
    font-size: inherit;
  }

  .title__dot {
    color: #2ea3f2;
    font-size: 1.5rem;
    margin-inline: 1rem;
  }
}

@media only screen and (min-width: 493px) and (max-width: 1300px) {
  .os-image > img,
  .os-image > .v-img__img {
    object-position: left -50px !important;
  }

  .os-lg > img {
    object-position: left !important;
  }
}

@media only screen and (min-width: 1023px) and (max-width: 1195px) {
  .o-img-2 > img {
    object-position: center -50px !important;
  }
}

.mobile-partner-slider .swiper-wrapper {
  transition-timing-function: linear !important;
}
</style>
