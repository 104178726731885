<template>
  <section class="section--carousel">
    <div class="section__container">
      <p class="text-left pa-0 mb-5 d-lg-none">
        <span
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            height: 2.5rem;
            width: 2.5rem;
            background: red;
            border-radius: 2rem;
            background-color: var(--secondary-color-lighten);
            opacity: 0.5;
          "
          ><v-icon class="text-left text-disabled"
            >mdi-gesture-swipe-horizontal</v-icon
          ></span
        >
      </p>
      <h2 class="section__title dms700 mb-10 text-left">
        DIGU <span class="dms600">Partners</span>
      </h2>
      <v-carousel
        hide-delimiters
        class="carousel--desktop"
        v-model="sliderProgress"
      >
        <template v-slot:prev="{ props }">
          <v-btn
            variant="link"
            elevation="0"
            @click="props.onClick"
            style="height: 100%; width: 64px; border-radius: 0px"
          >
            <v-icon size="x-large">mdi-chevron-left</v-icon>
          </v-btn>
        </template>
        <template v-slot:next="{ props }">
          <v-btn
            variant="link"
            elevation="0"
            @click="props.onClick"
            style="height: 100%; width: 64px; border-radius: 0px"
          >
            <v-icon size="x-large">mdi-chevron-right</v-icon>
          </v-btn>
        </template>
        <!-- Item 1 -->
        <v-carousel-item class="carousel-item">
          <v-sheet color="#e3f5ff00" height="100%">
            <div
              class="d-flex flex-column fill-height justify-center align-center"
            >
              <v-row class="section__row" height="100%">
                <v-col
                  class="section__col d-flex flex-column fill-height justify-center align-start"
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                >
                  <a
                    class="section__subtitle-2 o600"
                    href="https://blinknow.org/"
                    target="_blank"
                    >BlinkNow Foundation</a
                  >
                  <h3 class="section__title-2 text-left dms700 mb-4">
                    Create a world where every child is safe, educated, and
                    loved
                  </h3>
                  <p class="section__description-2 text-left m500">
                    BlinkNow, a global non-profit dedicated to transforming the
                    lives of underserved children, and DIGU, a leading
                    provider of recruiting and staffing services in the
                    technology sector, have joined forces to offer the children
                    of Kopila Valley, in rural Nepal, new and enhanced
                    opportunities in the field of technology.
                  </p>
                  <a
                    href="https://blinknow.org/"
                    class="link 0600 mt-7 section__description-2"
                    target="_blank"
                    >Visit BlinkNow Foundation's Website
                    <v-icon size="x-small">mdi-open-in-new</v-icon></a
                  >
                </v-col>
                <v-col
                  class="section__col d-flex flex-column"
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                >
                  <v-img
                    :src="
                      require('@/assets/img/smartworks-cares-carousel-slide-one.webp')
                    "
                    cover
                    eager
                    class="carousel__image ma-2"
                    :height="400"
                  >
                  </v-img>
                </v-col>
              </v-row>
            </div>
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item class="carousel-item">
          <v-sheet color="#e3f5ff00" height="100%">
            <div
              class="d-flex flex-column fill-height justify-center align-center"
            >
              <v-row class="section__row" height="100%">
                <v-col
                  class="section__col d-flex flex-column fill-height justify-center align-start"
                  cols="12"
                  sm="12"
                  md="12"
                  lg="7"
                >
                  <a
                    class="section__subtitle-2 o600 os-sub-title"
                    href="https://www.cab.edu.np/"
                    target="_blank"
                    >College of Applied Business and Technology</a
                  >
                  <h3 class="section__title-2 text-left dms700 mb-4 os-title">
                    Empowering the next generation of Nepali professionals
                  </h3>
                  <p
                    class="section__description-2 text-left m500 os-description"
                  >
                    DIGU and the College of Applied Business and Technology are
                    combining efforts to provide students with valuable
                    enablement programs, engaging speaker sessions, and
                    sought-after internship opportunities. The collaboration
                    aims to equip students with essential skills and industry
                    insights, fostering a bridge between academic learning and
                    real-world application. Together, we are dedicated to
                    empowering the next generation of professionals and
                    preparing them for success in their careers.
                  </p>
                  <a
                    href="https://www.cab.edu.np/"
                    class="link 0600 mt-7 section__description-2"
                    target="_blank"
                    >Visit Website
                    <v-icon size="x-small">mdi-open-in-new</v-icon></a
                  >
                </v-col>
                <v-col
                  class="section__col d-flex flex-column"
                  cols="12"
                  sm="12"
                  md="12"
                  lg="5"
                >
                  <v-img
                    :src="
                      require('@/assets/img/smartworks-cares-carousel-slide-three.webp')
                    "
                    cover
                    eager
                    class="carousel__image ma-2 os-lg"
                    :height="400"
                  >
                  </v-img>
                </v-col>
              </v-row>
            </div>
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item class="carousel-item">
          <v-sheet color="#e3f5ff00" height="100%">
            <div
              class="d-flex flex-column fill-height justify-center align-center"
            >
              <v-row class="section__row" height="100%">
                <v-col
                  class="section__col d-flex flex-column fill-height justify-center align-start"
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                >
                  <a
                    class="section__subtitle-2 o600"
                    href="https://beamlab.co/"
                    target="_blank"
                    >BeamLab</a
                  >
                  <h3 class="section__title-2 text-left dms700 mb-4">
                    Empowering Young Talent through Impact Hiring
                  </h3>
                  
                  
                  <p class="section__description-2 text-left m500">
                    We’re excited to partner with BEAMLAB Pvt. Ltd. to nurture young talent by offering internships and entry-level positions. This collaboration provides emerging professionals with practical skills and a solid career foundation. Employees can smoothly transition from BEAMLAB to DIGU, gaining further career growth and access to offshore job opportunities, connecting young talents with the global tech industry.
                  </p>
                  <a
                    href="https://beamlab.co/"
                    class="link 0600 mt-7 section__description-2"
                    target="_blank"
                    >Visit BeamLab's Website
                    <v-icon size="x-small">mdi-open-in-new</v-icon></a
                  >
                </v-col>
                <v-col
                  class="section__col d-flex flex-column"
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                >
                  <v-img
                    :src="
                      require('@/assets/img/programs-section.webp')
                    "
                    cover
                    eager
                    class="carousel__image ma-2"
                    :height="400"
                  >
                  </v-img>
                </v-col>
              </v-row>
            </div>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
      <carousel
        :wrap-around="true"
        :itemsToShow="1"
        class="carousel--mobile"
        v-model="sliderProgress"
      >
        <slide class="m-carousel-slide">
          <div class="m-carousel-card">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="6">
                <keep-alive>
                  <v-img
                    eager
                    :src="
                      require('@/assets/img/smartworks-cares-carousel-slide-one.webp')
                    "
                    cover
                    class="m-carousel__image d-block d-md-none"
                  >
                  </v-img>
                </keep-alive>
                <a
                  class="m-carousel__subtitle o600 text-left"
                  href="https://blinknow.org/"
                  target="_blank"
                >
                  BlinkNow Foundation
                </a>
                <h3 class="m-carousel__title dms600 text-left mt-0">
                  Create a world where every child is safe, educated, and loved
                </h3>
                <p
                  class="m-carousel__description text-left m400 mt-3 text-left"
                >
                  BlinkNow, a global non-profit dedicated to transforming the
                  lives of underserved children, and DIGU, a leading
                  provider of recruiting and staffing services in the technology
                  sector, have joined forces to offer the children of Kopila
                  Valley, in rural Nepal, new and enhanced opportunities in the
                  field of technology.
                </p>
                <a
                  href="https://blinknow.org/"
                  class="link 0600 mt-7 section__description-2 text-left"
                  target="_blank"
                  >Visit BlinkNow Foundation's Website
                  <v-icon size="x-small">mdi-open-in-new</v-icon></a
                >
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <keep-alive>
                  <v-img
                    eager
                    :src="
                      require('@/assets/img/smartworks-cares-carousel-slide-one.webp')
                    "
                    cover
                    class="m-carousel__image ma-2 d-none d-md-block"
                  >
                  </v-img>
                </keep-alive>
              </v-col>
            </v-row>
          </div>
        </slide>
        <slide class="m-carousel-slide">
          <div class="m-carousel-card">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="6">
                <keep-alive>
                  <v-img
                    eager
                    :src="
                      require('@/assets/img/smartworks-cares-carousel-slide-three.webp')
                    "
                    cover
                    position="center -100px"
                    class="m-carousel__image d-block d-md-none os-image"
                  >
                  </v-img>
                </keep-alive>
                <a
                  class="m-carousel__subtitle o600 text-left"
                  href="https://www.cab.edu.np/"
                  target="_blank"
                >
                  College of Applied Business and Technology
                </a>
                <h3 class="m-carousel__title dms600 text-left mt-0">
                  Empowering the next generation of Nepali professionals
                </h3>
                <p
                  class="m-carousel__description text-left m400 mt-3 text-left"
                >
                  DIGU and the College of Applied Business and Technology are
                  combining efforts to provide students with valuable enablement
                  programs, engaging speaker sessions, and sought-after
                  internship opportunities. The collaboration aims to equip
                  students with essential skills and industry insights,
                  fostering a bridge between academic learning and real-world
                  application. Together, we are dedicated to empowering the next
                  generation of professionals and preparing them for success in
                  their careers.
                </p>
                <a
                  href="https://www.cab.edu.np/"
                  class="link 0600 mt-7 section__description-2 text-left"
                  target="_blank"
                  >Visit College of Applied Business and Technology's Website
                  <v-icon size="x-small">mdi-open-in-new</v-icon></a
                >
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <keep-alive>
                  <v-img
                    eager
                    :src="
                      require('@/assets/img/smartworks-cares-carousel-slide-three.webp')
                    "
                    cover
                    position="center center"
                    class="m-carousel__image ma-2 d-none d-md-block o-img-2"
                  >
                  </v-img>
                </keep-alive>
              </v-col>
            </v-row>
          </div>
        </slide>
        <slide class="m-carousel-slide">
          <div class="m-carousel-card">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="6">
                <keep-alive>
                  <v-img
                    eager
                    :src="
                      require('@/assets/img/programs-section.webp')
                    "
                    cover
                    class="m-carousel__image d-block d-md-none"
                  >
                  </v-img>
                </keep-alive>
                <a
                  class="m-carousel__subtitle o600 text-left"
                  href="https://beamlab.co/"
                  target="_blank"
                >
                  Beamlab
                </a>
                <h3 class="m-carousel__title dms600 text-left mt-0">
                  Empowering Young Talent through Impact Hiring
                </h3>
                <!-- <h4>
                    Impact Hiring Partnership
                  </h4>
                  <p class="m-carousel__description text-left m400 mt-3 text-left">
                    Smartworks Technology Partners is excited to announce our impactful collaboration with Beam Lab Pvt. Ltd., aimed at nurturing young talent and providing them with invaluable industry experience. This partnership offers internships and entry-level positions, allowing emerging professionals to gain practical skills and a solid foundation for their careers.
                  </p> -->
                  <h4 class="text-left">
                    Career Growth and Global Opportunities
                  </h4>
                  <p class="m-carousel__description text-left m400 mt-3 text-left">
                    Our collaboration with BeamLab ensures a seamless transition for employees from BeamLab to Smartworks, where they can further develop their careers and access offshore job opportunities. Through this partnership, we are committed to fostering career growth and connecting young talents with global opportunities, enabling them to thrive in the competitive tech industry.
                  </p>
                <a
                  href="https://beamlab.co/"
                  class="link 0600 mt-7 section__description-2 text-left"
                  target="_blank"
                  >Visit BeamLab's Website
                  <v-icon size="x-small">mdi-open-in-new</v-icon></a
                >
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <keep-alive>
                  <v-img
                    eager
                    :src="
                      require('@/assets/img/smartworks-cares-carousel-slide-one.webp')
                    "
                    cover
                    class="m-carousel__image ma-2 d-none d-md-block"
                  >
                  </v-img>
                </keep-alive>
              </v-col>
            </v-row>
          </div>
        </slide>
      </carousel>
      <!-- <p class="text-disabled mt-5">Swipe to View More <v-icon>mdi-chevron-right</v-icon></p> -->
    </div>
  </section>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';
export default {
  name: 'SmartworksCentral',
  components: {
    carousel: Carousel,
    slide: Slide,
  },
  data: () => ({
    sliderProgress: 0,
  }),
};
</script>

<style scoped>
.section--carousel {
  background-color: var(--secondary-color-lightest);
  padding-block: 4rem;
}

@media only screen and (min-width: 1280px) {
  .section--carousel {
    padding-bottom: 3rem;
  }
}

.section__row {
  padding-inline: 6rem !important;
}

.section__title {
  margin-inline: auto;
  color: var(--dark-3) !important;
  text-align: left;
  line-height: 100%;
}

.section__title .dms600 {
  color: var(--accent-red) !important;
}

.section__subtitle-2 {
  color: var(--accent-red);
  text-align: center;
  width: 100%;
}

@media only screen and (min-width: 1280px) {
  .section__subtitle-2 {
    text-align: left;
  }
}

.section__title {
  text-align: left;
  line-height: 120%;
  width: 100%;
  text-align: center;
}

@media only screen and (min-width: 1280px) {
  .section__title {
    text-align: left;
    margin-top: initial;
  }
}

.carousel--desktop {
  display: none;
}

@media only screen and (min-width: 1280px) {
  .carousel--desktop {
    display: initial;
  }

  .carousel--mobile {
    display: none;
  }
}

.carousel__image {
  border-radius: 2rem !important;
}

.carousel__caption {
  width: 100%;
  margin-bottom: 3rem;
}

.m-carousel__subtitle,
.section__subtitle-2 {
  text-decoration: none;
}

.link {
  color: var(--accent-red);
  text-decoration: none;
  display: inline-block;
  text-align: left;
  width: 100%;
}

.link:hover {
  text-decoration: underline;
}

.section__title-2 {
  line-height: 120%;
}
</style>
