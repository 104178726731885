<template>
  <section class="disclaimer-section">
    <div class="section__container">
      <h2 class="section__title">What to expect after joining our <span style="color: var(--accent-red);">Talent
          Pool?</span></h2>
      <div class="cards-container">
        <div class="card" v-for="data, index in dis" :key="index">
          <img class="card__icon" :src="require(`@/assets/img/${data.icon}`)" alt="">
          <p class="card__text">{{ data.text }}</p>
        </div>
      </div>

      <div class="callout">
        <div class="callout__body">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="6">
              <h3 class="callout__title">What <span style="color: var(--accent-red)">NOT</span> to expect</h3>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="6" class="d-flex align-center ">
              <div>
                <p class="callout__sub-title">Immediate job placement/interviews</p>
                <p class="callout__description">Matching your skill sets with potential clients’ needs can take time. We ask you to be patient with us.</p>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="callout__footer">
          <p class="callout__footer-text">Do not leave your current job until our clients hire you.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "DisclaimerSection",
  data: () => ({
    dis: [
      {
        icon: 'd1.png',
        text: "Access to training and enablement programs to augment your skills."
      },
      {
        icon: "d2.png",
        text: "In-person and virtual workshops to review and build your resume."
      },
      {
        icon: 'd3.png',
        text: "Networking events"
      },
      {
        icon: 'd4.png',
        text: "Interviews with potential clients after they express interest in speaking with you."
      },
      {
        icon: 'd5.png',
        text: "Access to our online professional community."
      },
    ]
  })

}
</script>

<style scoped>
.disclaimer-section {
  text-align: left;
  padding: 1.5rem;
  padding-top: 3rem;
  background-color: var(--light-1);
}

.section__title {
  font-size: 2.25rem !important;
  width: 100%;
  /* max-width: rem; */
  line-height: 140%;
  font-family: 'DM Sans Bold', sans-serif;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.card {
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  width: 100%;
  background-color: #fff;
  border-radius: 0.4rem;
}

@media only screen and (min-width: 768px) {
  .card {
    width: 45%;
  }
}

@media only screen and (min-width: 1200px) {
  .card {
    width: initial;
    max-width: 30rem;
  }
}

.card__icon {
  margin-bottom: 1rem;
  width: 3rem;
  height: 3rem;
}

.card__text {
  font-family: 'Outfit Medium', sans-serif;
}

.callout {
  border: 3px solid var(--accent-red);
  margin-block: 2rem;
}

.callout__body {
  padding: 2rem;
}

.callout__title {
  font-size: 2.5rem;
  max-width: 15rem;
  font-family: 'DM Sans Bold', sans-serif;
}

.callout__sub-title {
  font-size: 1.25rem;
  font-family: 'DM Sans Bold', sans-serif;
}

.callout__description {
  font-family: 'Manrope Medium', sans-serif;
}

.callout__footer {
  background-color: var(--accent-red);
  color: var(--light-1);
  padding: 0.5rem;
  text-align: center;
  font-size: 1.25rem;
}
</style>
