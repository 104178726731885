<template>
  <div class="category-section">
    <div class="category-header" :class="{ highlight: highlightHeader }">
      <span>{{ title }}</span>
    </div>
    <div class="metric-container">
      <div class="metric" v-for="(item, index) in metrics" :key="index">
        <div class="metric-label">{{ item.label }}</div>
        <div class="metric-bar">
          <div
            class="metric-progress"
            :style="{ width: item.value * 2 + '%' }"
          ></div>
        </div>
        <span class="metric-value">{{ item.value }}</span>
      </div>
    </div> 
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    metrics: Array,
    highlightHeader: Boolean, // New prop
  },
};
</script>


<style scoped>
.category-section {
  display: flex;
  align-items: flex-end;
  text-align: left;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.category-header {
  background: #fe918b;
  color: white;
  padding: 15px;
  border-radius: 8px 0 0 8px;
  font-weight: bold;
  text-align: center;
  width: 200px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.category-header.highlight {
  background-color: #ff6860;
  color: white;
}

.metric-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 2rem;
  gap: 12px;
}

.metric {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.metric-label {
  flex: 2;
  font-size: 14px;
  color: #333;
  text-align: right;
  height: 100%;
  min-width: 150px;
}

.metric-bar {
  background: #f0e6e6;
  /* flex: 1;  */
  width: 300%;
  height: 20px;
  margin: 1rem 1rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.metric-progress {
  background: #fe716a;
  height: 100%;
  border-radius: 0;
  transition: width 3s ease-out;
}

.metric-value {
  flex: 0 0 20px; /* Fixed width to prevent resizing */
  font-size: 14px;
  color: #333;
  text-align: left;
}

@media screen and (max-width: 1068px) {
  .category-header {
    width: 100%;
    border-radius: 8px 8px 0px 0px;
  }
  .category-section {
    display: grid;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    gap: 5px;
  }
}

@media screen and (max-width: 774px) {
  .category-section {
    align-content: space-around;
  }
}
</style>
