<template>
  <section class="section--mission">
    <div class="section__container">
      <v-row class="section__row">
        <v-col
          class="section__col text-container"
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="6"
          xl="6"
        >
          <h2 class="section__title dms700 section__title">Our Mission</h2>
          <keep-alive>
            <v-img
              :src="require('@/assets/img/about-mission.webp')"
              eager
              cover
              class="m-section__image"
            ></v-img>
          </keep-alive>
          <div class="section__paragraph-container">
            <p class="section__paragraph m500 section__description">
              Empower Nepali professionals to deliver world-class software
              services while driving success for our customers.
            </p>
          </div>
        </v-col>
        <v-col
          class="section__col img-container d-none d-md-block"
          cols="12"
          sm="12"
          md="6"
          lg="6"
          xl="6"
        >
          <keep-alive>
            <v-img
              :src="require('@/assets/img/about-mission.webp')"
              cover
              eager
              class="section__image"
            ></v-img>
          </keep-alive>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MissionSection',
};
</script>

<style scoped>
.section--mission {
  background-color: var(--light-1);
  padding-block: 4rem;
}

.section__row {
  padding-inline: 0px;
}

.section__col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (min-width: 1280px) {
  .text-container {
    text-align: left;
  }
}

.section__subtitle {
  color: var(--accent-red);
  text-align: left;
}

@media only screen and (min-width: 1280px) {
  .section__subtitle {
    text-align: left;
  }
}

.section__title {
  text-align: left;
  color: var(--accent-red);
}

@media only screen and (min-width: 1280px) {
  .section__title {
    text-align: left;
    line-height: 100%;
  }
}

.section__paragraph-container {
  text-align: left;
}

@media only screen and (min-width: 1280px) {
  .section__paragraph-container {
    width: 90%;
  }
}

.section__paragraph {
  margin-block: 1rem;
}

@media only screen and (min-width: 1200px) {
  .section__paragraph {
    text-align: left;
  }
}

.section__image {
  border-radius: 0.8rem;
  margin-left: auto;
  aspect-ratio: 4/3;
}

.m-section__image {
  margin-block: 2rem;
  border-radius: 1rem;
  max-height: 200px;
}

@media only screen and (min-width: 700px) {
  .m-section__image {
    max-height: 300px;
  }
}

@media only screen and (min-width: 960px) {
  .m-section__image {
    display: none;
  }
}
</style>
