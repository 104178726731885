<template>
  <section class="section--final">
    <div class="section__container">
      <v-row class="section__row">
        <v-col class="section__col" cols="12" sm="12" md="12" lg="12" xl="12">
          <div class="section__info">
            <p class="section__description dm-sans text-left">
              Once you earn a Level 2 Verified Badge, DIGU takes your profile to
              the next level. We actively showcase and circulate your profile to
              our global partner clients, ensuring maximum visibility and
              exposure to top opportunities. Additionally, your profile will be
              prominently featured in our client portal, increasing your chances
              of being noticed by key decision-makers. Throughout the hiring
              process, DIGU provides continuous support, helping you connect
              with the right opportunities and succeed in your career journey.
            </p>
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FinalSection',
};
</script>

<style scoped>
.section--final {
  background: var(--secondary-color-lighten);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 25rem;
}

@media only screen and (min-width: 1280px) {
  .section--about {
    padding-inline: 0px;
    background-size: 100vw;
    background-position-y: bottom;
  }
}

@media only screen and (min-width: 1280px) {
  .section__row {
    padding-bottom: 5rem;
  }
}

@media only screen and (min-width: 1280px) {
  .section__col:first-child {
    display: flex;
    align-items: center;
  }
}

@media only screen and (min-width: 1280px) {
  .section__info {
    padding: 0px;
    width: 100%;
  }
}

@media only screen and (min-width: 1280px) {
  .section__col {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.section__description {
  font-size: 1rem;
  margin-bottom: 2rem;
  line-height: 150%;
}

@media only screen and (min-width: 1280px) {
  .section__description {
    margin-bottom: initial;
    width: 100%;
  }
}
</style>
