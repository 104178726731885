<script setup>
import { Carousel, Slide } from 'vue3-carousel';
import { ref } from 'vue';
import DeveloperFeedbackCard from './partials/DeveloperFeedbackCard.vue';

const feedbacks = ref([
  {
    name: 'Anwesh Dahal',
    position: 'Fullstack Developer',
    message: `The DIGU Resume Building Workshop was a game changer! It helped me spot the weak spots in my resume and gave me some real eye opening insights into what the industry's looking for. Now I've got a better handle on how to structure things and where I need to shine.`,
    hasImage: true,
  },
  {
    name: 'Bishesh Dangol',
    position: 'Flutter Developer',
    message: `Attending the Resume Builder Workshop was incredibly beneficial. It opened my eyes to the critical areas where my resume needed improvement. The experts shared up-to-date industry standards and tips on how to make a resume more appealing to employers. I particularly appreciated the focus on structuring content for impact, which has equipped me with the tools to present my skills and experiences more effectively.`,
    hasImage: true,
  },
  {
    name: 'Nischal Shakya',
    position: 'Software Engineer',
    message: `I recently attended a Communication Workshop by DIGU, and it was an amazing experience! The session provided practical tips on effective communication and acing interviews. The trainer was engaging, relatable, and focused on real-world scenarios. I left feeling prepared and confident to face interviews. Big thanks to DIGU for this invaluable opportunity-it truly made a difference in boosting my skills!`,
    hasImage: true,
  },
  {
    name: 'Hari Roka',
    position: 'Senior QA Engineer',
    message: `I attended a communication workshop in September, and it was impactful. Through real-time scenarios, I gained practical communication skills, like explaining technical concepts to non-technical audiences and practicing constructive feedback. The role-playing exercises and immediate feedback were especially valuable. I've started applying these techniques in meetings, and the improvement is noticeable. Highly recommended for anyone looking to refine their communication skills.`,
    hasImage: true,
  },
  
]);

const carouselModel = ref(0);
const carouselRef = ref(null);

// Method to move to the next slide
const goNext = () => {
  carouselRef.value.next();
};

// Method to move to the previous slide
const goPrev = () => {
  carouselRef.value.prev();
};
</script>

<template>
  <div class="feedback-carousel">
    <!-- Prev Button -->
    <button class="carousel-arrow prev" @click="goPrev">
      <v-icon>mdi-chevron-left</v-icon>
    </button>

    <!-- Carousel Component -->
    <Carousel
      class="feedbacks"
      v-model="carouselModel"
      :wrap-around="true"
      :items-to-show="1"
      ref="carouselRef"
    >
      <Slide
        v-for="(feedback, index) in feedbacks"
        :key="index"
        class="feedbacks__slide"
      >
        <div class="pa-3 w-100">
          <DeveloperFeedbackCard
            :message="feedback.message"
            :name="feedback.name"
            :position="feedback.position"
            :has-image="feedback.hasImage"
          />
        </div>
      </Slide>
    </Carousel>

    <!-- Next Button -->
    <button class="carousel-arrow next" @click="goNext">
      <v-icon>mdi-chevron-right</v-icon>
    </button>
  </div>

  <!-- <p class="text-right" style="font-size: 0.9rem; color: var(--gray-4)">
    Swipe to read more <v-icon>mdi-chevron-double-right</v-icon>
  </p> -->
</template>

<style scoped>
.feedback-carousel {
  position: relative;
  display: flex;
  align-items: center;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  z-index: 10;
}

.carousel-arrow.prev {
  left: -25px; /* Adjust for distance from the left side */
}

.carousel-arrow.next {
  right: -25px; /* Adjust for distance from the right side */
}

.feedbacks {
  width: 100%;
  height: 100%;
}

.feedbacks__slide {
  height: 100%;
}
</style>
