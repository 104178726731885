<template>
  <div class="verified-developers" id="view">
    <hero-section
      :subTitle="subTitle"
      :title="title"
      :description="description"
      :showPurpleWaves="true"
    />
    <coder-section />
    <communication-ready-section />
    <final-section />
  </div>
</template>

<script>
// Global Components
import HeroSection from '@/components/partials/HeroSection.vue';

// Local Components
import CoderSection from './Sections/CoderSection.vue';
import CommunicationReadySection from './Sections/CommunicationReadySection.vue';
import FinalSectionVue from './Sections/FinalSection.vue';
export default {
  name: 'CertifiedDeveloper',
  components: {
    'hero-section': HeroSection,
    'coder-section': CoderSection,
    'communication-ready-section': CommunicationReadySection,
    'final-section': FinalSectionVue,
  },

  data: () => ({
    subTitle: 'CORE SKILLS · COMMUNICATOR',
    title: 'VERIFIED BADGES',
    description:
      "At DIGU, our Verified Badges are not just symbols—they are a mark of your expertise, reliability, and commitment to delivering exceptional results. These badges set you apart as a top-tier professional, trusted by clients and peers alike. Here’s what each badge represents:",
  }),
  created() {
    document.getElementById('loaderSection').classList.add('d-none');
  },
};
</script>
