<template>
  <section class="hero-section">
    <div class="section__container">
      <v-row class="section__row">
        <v-col
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="6"
          class="section__col section__text"
        >
          <h1 class="dms700 section__title">
            Fuel Your Innovation <br />
            <span class="dms700 normal">Drive Meaningful Impact</span>
            <!-- <br /> -->
          </h1>
          <keep-alive>
            <v-img
              eager
              :src="require('@/assets/img/home-hero.webp')"
              :lazy-src="require('@/assets/img/lazy-img.jpg')"
              class="mobile-section__img"
              cover
            >
              <template v-slot:placeholder>
                <div class="d-flex align-center justify-center fill-height">
                  <v-progress-circular
                    color="grey-lighten-4"
                    indeterminate
                  ></v-progress-circular>
                </div> </template
            ></v-img>
          </keep-alive>
          <p class="section__description m500">
            Create lasting change by hiring our professionals to power your
            organization. Your partnership doesn’t just achieve your goals—it
            fuels dreams, supports education, and builds futures.
          </p>

          <p class="section__description mb-6 m500">
            Seamlessly integrate seasoned software developers, QA engineers, and
            designers into your team. Pre-vetted, thoroughly trained, and ready
            to deliver exceptional results from day one.
          </p>

          <v-row justify="start" class="mt-n3">
            <v-col
              cols="12"
              xs="12"
              sm="6"
              lg="6"
              class="text-start btn-container"
            >
              <v-btn
                class="custom-btn btn-solid"
                size="large"
                elevation="0"
                href="/hire-a-professional"
                >Hire a Professional</v-btn
              >
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="6"
              lg="6"
              class="text-start btn-container"
            >
              <v-btn
                class="custom-btn btn-outlined"
                size="large"
                elevation="0"
                href="/outsource-your-project"
                >Outsource Your Project</v-btn
              >
            </v-col>
            
          </v-row>
          
          <!-- TODO: Fix the position -->
          <v-row justify="start">
            <v-col class="col_link">
              <a
                class="custom_link"
                size="large"
                elevation="0"
                href="https://calendly.com/digu/30-minutes-with-digu?month=2024-12"
                target="_blank"
                rel="noopener noreferrer"
              >
                Let's Talk
                <v-icon size="x-small" class="ms-2">mdi-open-in-new</v-icon>
              </a>
            </v-col>
          </v-row>


          <!-- <v-btn class="section__button o500" elevation="0" size="x-large" href="/why-smartworks-for-businesses">Learn More</v-btn> -->
        </v-col>
        <v-col class="section__col section__image ps-6" cols="5">
          <keep-alive>
            <v-img
              :src="require('@/assets/img/home-hero.webp')"
              :lazy-src="require('@/assets/img/lazy-img.jpg')"
              cover
              :eager="true"
              class="section__img"
            ></v-img>
          </keep-alive>
        </v-col>
      </v-row>
      <div class="partners-card">
        <v-row justify="center">
          <v-col cols="2" class="title__section">
            <p class="ws500 partners__title">Partners</p>
          </v-col>
          <v-col cols="8" sm="12" md="10" lg="10" class="logo__section">
            <v-img
              v-for="(partner, index) in partners"
              :key="index"
              class="partners__logo"
              @click="route(partner.url)"
              :src="partner.src"
            ></v-img>
          </v-col>
        </v-row>
      </div>
      <div class="partner-card--mobile mt-4">
        <h3 class="mobile-partner__title my-5">Our Partners</h3>
        <swiper-container
          :slides-per-view="1"
          :space-between="spaceBetween"
          :centered-slides="true"
          :navigation="false"
          :pagination="false"
          :free-mode="true"
          :lazy="false"
          :loop="true"
          :cssMode="true"
          :autoplay="{
            delay: 1500,
            disableOnInteraction: false,
          }"
          class="mobile-partner-slider"
        >
          <swiper-slide
            v-for="(partner, index) in partners"
            :key="index"
            :virtual="true"
          >
            <v-img
              class="partners__logo"
              :src="partner.src"
              @click="route(partner.url)"
              :eager="true"
            ></v-img
          ></swiper-slide>
        </swiper-container>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { register } from 'swiper/element/bundle';
import logo from '../../../assets/img/Beamlab-logo.webp';
register();

const spaceBetween = ref(30);

const partners = ref([
  {
    src: require('@/assets/img/Okta.webp'),
    url: 'https://www.okta.com/',
  },
  {
    src: require('@/assets/img/Anthropic-Identity_Logo_Website.webp'),
    url: 'https://www.anthropicidentity.com/',
  },

  {
    src: require('@/assets/img/CAB.webp'),
    url: 'https://www.cab.edu.np/',
  },

  {
    src: require('@/assets/img/BlinkNow.webp'),
    url: 'https://blinknow.org/',
  },
  {
    src: logo,
    url: 'https://beamlab.co/',
  },
]);

onMounted(() => {
  let slider = document.querySelector('.swiper-wrapper');
  slider;
});

const route = (url) => {
  window.open(url, '_blank');
};
</script>

<style scoped>
.hero-section {
  position: relative;
  background-color: var(--light-1);
  background-image: url('@/assets/img/hero-section-bg.svg');
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  padding-block: 4rem 8rem;
}

@media only screen and (min-width: 1200px) {
  .hero-section {
    padding-bottom: 15rem;
  }
}

@media only screen and (min-width: 700px) {
  .hero-section {
    background-position-y: 1px;
  }
}

@media only screen and (min-width: 800px) {
  .hero-section {
    background-position-y: 1px;
  }
}

@media only screen and (min-width: 1280px) {
  .hero-section {
    background-size: contain;
    background-position-y: bottom;
  }
}

.section__row {
  margin-inline: auto;
  padding-inline: 0px;
}

.section__col {
  padding-inline: 0px;
}

.section__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.section__title {
  text-align: left;
  color: var(--dark-2);
  font-size: 2.25rem;
  font-style: normal;
  line-height: 110%;
  margin-bottom: 0.5rem;
}

.section__title > .dms700 {
  color: var(--accent-red);
}

.custom_link {
  color: var(--accent-red);
  text-decoration: none;
}

@media only screen and (min-width: 700px) {
  .section__title {
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .section__title {
    text-align: left;
    font-size: 2.5rem;
    margin-inline: inherit;
  }
}

.section__description {
  text-align: left;
  color: var(--dark-1);
  font-size: 1rem;
  font-style: normal;
  margin-bottom: 1rem;
}

@media only screen and (min-width: 1200px) {
  .section__description {
    text-align: left;
  }
}

.section__button {
  background-color: var(--secondary-color-lighten);
  color: var(--primary-color-darken);
  letter-spacing: 0px;
  text-transform: capitalize;
  font-size: 1.25rem;
}

@media only screen and (min-width: 1200px) {
  .section__button {
    margin-inline: initial;
  }
}

.section__image {
  display: none;
  align-items: center;
  justify-content: flex-end;
}

@media only screen and (min-width: 960px) {
  .section__image {
    display: flex;
  }
}

.section__img {
  border-radius: 0.8rem;
}

@media only screen and (min-width: 1024px) {
  .section__img {
    aspect-ratio: 1;
    margin-left: auto;
    border-radius: 0.8rem;
  }
}

.slide-buttons {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 1200px) {
  .slide-buttons {
    display: flex;
  }
}

.slide-button {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--primary-color-lighten);
  margin-bottom: 0.3rem;
  border-radius: 5rem;
}

.active {
  height: 3rem;
  background-color: var(--primary-color-darken);
}

.partners-card {
  display: none;
  position: absolute;
  z-index: 999;
  bottom: -2.5rem;
  left: 2.5vw;
  background-color: #fff;
  height: 8rem;
  border-radius: 0.75rem;
  box-shadow: 0px 19px 32px 0px rgba(0, 0, 0, 0.1);
  width: 95vw;
}

@media only screen and (min-width: 830px) {
  .partners-card {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (min-width: 1024px) {
  .partners-card {
    width: 80vw;
    left: 10vw;
  }
}

.partners-card .title__section {
  border-right: 1px solid var(--gray-2);
  display: none;
}

@media only screen and (min-width: 1024px) {
  .partners-card .title__section {
    display: grid;
    align-content: center;
  }
}

.partners__title {
  color: var(--gray-1);
  font-size: 1.5rem;
}

.partners__logo {
  /* display: inline-flex; */
  max-height: 3rem;
  filter: saturate(0%) contrast(15%);
  transition: filter cubic-bezier(0.595, 0.06, 0.03, 0.835) 300ms;
  cursor: pointer;
  align-items: left;
  /* justify-content: flex-start; */
  margin: 0rem 2.5rem;
}

.partners__logo:hover {
  filter: saturate(100%) contrast(100%);
}

.logo__section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 2rem;
}

.mobile-section__img {
  display: flex;
  width: 100%;
  margin-block: 1rem 1.5rem;
  border-radius: 0.8rem;
}

@media only screen and (min-width: 500px) {
  .mobile-section__img {
    height: 450px;
  }
}

@media only screen and (min-width: 960px) {
  .mobile-section__img {
    display: none;
  }
}

/* Home Hero Buttons */

.hero__button {
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: 0px;
}

.hero__button--solid {
  /* "Color" wird auf Deutsch als "Farbe" übersetzt  */
  background-color: var(--primary-color-darken);
  color: white;
  font-family: 'Manrope Bold', sans-serif;
  font-weight: 700;
}

.hero__button--outlined {
  border: 2px var(--primary-color-darken) solid;
  color: var(--primary-color-darken);
  font-family: 'Manrope Bold', sans-serif;
  font-weight: 700;
}

.custom-btn {
  text-transform: capitalize;
  background: tran;
  letter-spacing: 0px;
  gap: 1rem;
  min-width: 250px;
  
}

.custom-btn.btn-solid {
  /* "Color" wird auf Deutsch als "Farbe" übersetzt  */
  background-color: var(--primary-color-darken);
  color: white;
  font-family: 'Manrope Bold', sans-serif;
  font-weight: 700;
}

.custom-btn.btn-outlined {
  border: 2px var(--primary-color-darken) solid;
  color: var(--primary-color-darken);
  font-family: 'Manrope Bold', sans-serif;
  font-weight: 700;
}

.partner-card--mobile {
  position: absolute;
  z-index: 999;
  bottom: -2.5rem;
  background-color: #fff;
  left: 8vw;
  right: 8vw;
  height: 9rem;
  border-radius: 0.75rem;
  box-shadow: 0px 19px 32px 0px rgba(0, 0, 0, 0.1);
  width: 84vw;
}

@media only screen and (min-width: 830px) {
  .partner-card--mobile {
    display: none;
  }
}

.mobile-partner__title {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

/* @media only screen and (max-width: 960px) {
  .btn-container {
    text-align: center !important;
  }
} */

.mt-n3 {
  margin-bottom: -rem; /* Adjust the value as needed to bring the link closer */
}

</style>
