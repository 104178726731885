<template>
  <div class="customer--blog" id="view">
    <hero-section
      v-if="currentBlog"
      :subTitle="currentBlog.companyName"
      :title="currentBlog.title"
    />
    <main-section v-if="currentBlog" :mainSection="currentBlog.mainSection" />
    <challenge-and-solutions-section
      v-if="currentBlog"
      :sectionData="currentBlog.challengeSolutionSection"
    />
    <more-info-section
      v-if="currentBlog"
      :sectionData="currentBlog.moreInfoSection"
    />
    <testimonial-section
      v-if="currentBlog"
      :sectionData="currentBlog.testimonials"
    />
  </div>
</template>

<script>
// Configs
import blogs from '@/config/blogs';

// Global Components
import HeroSectionVue from '@/components/partials/HeroSection.vue';

// Local Components
import MainSectionVue from '../customerBlog/Sections/MainSection.vue';
import ChallengeAndSolutionSectionVue from '../customerBlog/Sections/ChallengeAndSolutionSection.vue';
import MoreInfoSectionVue from '../customerBlog/Sections/MoreInfoSection.vue';
import TestimonialSection from '../customerBlog/Sections/TestimonialSection.vue';
export default {
  data: () => ({
    currentBlog: new Object(),
  }),
  components: {
    'hero-section': HeroSectionVue,
    'main-section': MainSectionVue,
    'challenge-and-solutions-section': ChallengeAndSolutionSectionVue,
    'more-info-section': MoreInfoSectionVue,
    'testimonial-section': TestimonialSection,
  },
  created() {
    this.currentBlog = blogs[this.$route.params.slug];
    if (!this.currentBlog) {
      this.$router.push('/customers');
    }
    document.getElementById('loaderSection').classList.add('d-none');
  },
};
</script>

<style></style>
