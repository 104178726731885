<template>
  <section class="section--testimonial">
    <v-row class="section__row">
      <v-col class="section__col i-section" cols="6" lg="3">
        <v-img
          eager
          class="section__img"
          :width="325"
          :height="325"
          cover
          :src="
            require(`@/assets/img/${sectionData[currentTestimonial]?.image}`)
          "
        ></v-img>
      </v-col>
      <v-col class="section__col p-section" cols="6" lg="9">
        <v-img
          eager
          class="section__icon"
          :width="129"
          :src="require(`@/assets/img/quotes.svg`)"
        ></v-img>
        <div class="section__paragraphs">
          <p
            class="section__paragraph m500"
            v-for="(paragraph, index) in sectionData[currentTestimonial]
              .paragraphs"
            :key="index"
          >
            {{ paragraph }}
          </p>
          <p class="name o600">
            {{ sectionData[currentTestimonial].name }},
            {{ sectionData[currentTestimonial].designation }},
            {{ sectionData[currentTestimonial].companyName }}
          </p>
        </div>
      </v-col>
    </v-row>
    <p class="text-center">
      <v-btn icon rounded class="mr-4" @click="previousTestimonial">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn icon rounded @click="nextTestimonial">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </p>
  </section>
</template>

<script>
export default {
  name: 'TestimonialSection',
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    currentTestimonial: 0,
  }),
  methods: {
    previousTestimonial() {
      if (this.currentTestimonial > 0) {
        this.currentTestimonial -= 1;
      } else {
        this.currentTestimonial = this.sectionData.length - 1;
      }
    },
    nextTestimonial() {
      if (this.currentTestimonial < this.sectionData.length - 1) {
        this.currentTestimonial += 1;
      } else {
        this.currentTestimonial = 0;
      }
    },
  },
};
</script>

<style scoped>
.section--testimonial {
  padding-block: 3rem;
}

.section__row {
  max-width: 1400px;
  margin-inline: auto;
}

.i-section {
  background-color: var(--secondary-color-lighten);
}

.section__img {
  border-radius: 300px;
}

.p-section {
  display: flex;
  align-items: flex-start;
  background-color: var(--primary-color-lighten);
  padding: 1.5rem;
}

.section__icon {
  margin-right: 1.5rem;
}

.section__paragraphs {
  text-align: left;
  color: var(--light-1);
  height: 100%;
}

.section__paragraph {
  margin-bottom: 1.25rem;
}

.name {
  margin-top: auto;
  font-size: 1.1rem;
}
</style>
