<template>
  <section class="section--challenge-and-solutions">
    <h2 class="section__title dms700">
      {{ sectionData.sectionTitle }}
    </h2>
    <v-row
      class="infographic-sub-section"
      v-if="sectionData.infographicSection"
    >
      <v-col
        class="infographic"
        cols="3"
        v-for="(infographic, index) in sectionData.infographicSection"
        :key="index"
      >
        <div class="info__container">
          <p class="info__percentage dms700">
            {{ infographic.sectionPercentage }}
          </p>
          <p class="info__description m500">
            {{ infographic.sectionDescription }}
          </p>
        </div>
      </v-col>
    </v-row>
    <!-- Challenge Section -->
    <div class="challenge__sub-section">
      <v-row class="sub-section__row">
        <v-col
          class="sub-section__col"
          :cols="sectionData.challengeSection.image ? 8 : 9"
        >
          <p class="sub-section__title dms600">The Challenge</p>
          <p
            class="sub-section__paragraph m500"
            v-for="(paragraph, index) in sectionData.challengeSection
              .paragraphs"
            :key="index"
          >
            {{ paragraph }}
          </p>
        </v-col>
        <v-col
          class="sub-section__col"
          v-if="sectionData.challengeSection.image"
        >
          <v-img
            eager
            class="sub-section__image"
            :src="require(`@/assets/img/${sectionData.challengeSection.image}`)"
            cover
          ></v-img>
        </v-col>
      </v-row>
    </div>
    <!-- Solution Section -->
    <div class="solution__sub-section">
      <p class="sub-section__title dms700">The Solution</p>
      <v-row class="sub-section__row">
        <v-col
          class="sub-section__col"
          :cols="sectionData.solutionSection.image ? 8 : 9"
        >
          <p
            class="sub-section__paragraph m500"
            v-for="(paragraph, index) in sectionData.solutionSection.paragraphs"
            :key="index"
          >
            {{ paragraph }}
          </p>
        </v-col>
        <v-col
          class="sub-section__col"
          v-if="sectionData.solutionSection.image"
        >
          <v-img
            eager
            class="sub-section__image"
            :src="require(`@/assets/img/${sectionData.solutionSection.image}`)"
            cover
          ></v-img>
        </v-col>
      </v-row>
    </div>
    <!-- Product Section -->
    <div class="solution__sub-section">
      <p class="sub-section__title dms700">The Product</p>
      <v-row class="sub-section__row">
        <v-col
          class="sub-section__col"
          :cols="sectionData.productsSection.image ? 8 : 9"
        >
          <p
            class="sub-section__paragraph m500"
            v-for="(paragraph, index) in sectionData.productsSection.paragraphs"
            :key="index"
          >
            {{ paragraph }}
          </p>
        </v-col>
        <v-col
          class="sub-section__col"
          v-if="sectionData.productsSection.image"
        >
          <v-img
            eager
            class="sub-section__image"
            :src="require(`@/assets/img/${sectionData.productsSection.image}`)"
            cover
          ></v-img>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  name: "ChallengeAndSolutionsSection",
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.section--challenge-and-solutions {
  padding-block: 3rem;
  background-color: var(--light-1);
}
.section__title {
  color: var(--dark-1);
  text-align: left;
  max-width: 1400px;
  margin-inline: auto;
  font-size: 2.25rem;
  line-height: 120%;
  letter-spacing: 0px;
  text-transform: capitalize;
}

.infographic-sub-section {
  max-width: 1400px;
  margin-inline: auto;
  margin-block: 3rem;
}

.infographic {
  text-align: left;
  border-left: 1px solid var(--gray-2);
}

.infographic:first-child {
  border: none;
}

.info__container {
  padding: 1rem;
}

.info__percentage {
  font-size: 2rem;
  color: var(--primary-color-lighten);
}

.challenge__sub-section,
.solution__sub-section,
.product__sub-section {
  margin-block: 1rem 2rem;
  max-width: 1400px;
  margin-inline: auto;
}

.sub-section__title {
  font-size: 1.25rem;
  text-align: left;
  margin-bottom: 1rem;
}

.sub-section__paragraph {
  text-align: justify;
  margin-bottom: 1rem;
}

.sub-section__paragraph:last-child {
  margin-bottom: 0px;
}
</style>
