<template>
  <section
    class="section--hero"
    :class="{
      wave: showWhiteWave,
      blueWave: showBlueWaves,
      darkerWave: showDarkerWave,
      blueWave3: showBlueWaves3,
      purpleWave: showPurpleWaves,
      lightBlueWave: showLightBlueWaves,
    }"
  >
    <div class="section__container text-left">
      <v-row class="section__row">
        <v-col cols="12" :md="imageName ? '6' : '12'" class="section__col">
          <div class="text__container">
            <p
              class="section__subtitle o500"
              v-if="subTitle"
              v-html="subTitle"
            ></p>
            <h1 class="section__title o600" v-html="title" v-if="title"></h1>
            <keep-alive>
              <v-img
                :src="require(`@/assets/img/${imageName}`)"
                cover
                :eager="true"
                class="m-section__image"
                v-if="imageName"
              ></v-img>
            </keep-alive>
            <p
              class="section__description m500 mt-3 mb-6"
              v-if="description"
              v-html="description"
            ></p>
            <a
              class="hero__btn m600"
              variant="text"
              elevation="0"
              v-if="hasButton"
              :href="buttonUrl"
              append-icon="mdi-open-in-new"
              >{{ buttonText }}
              <v-icon size="x-small" class="ms-2">mdi-open-in-new</v-icon></a
            >
          </div>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          class="section__col d-none d-md-block"
          v-if="imageName"
        >
          <keep-alive>
            <v-img
              class="section__image"
              :eager="true"
              style="border-radius: 0.8rem"
              :src="require(`@/assets/img/${imageName}`)"
              :aspect-ratio="16 / 9"
              cover
            ></v-img>
          </keep-alive>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeroSection',
  props: {
    subTitle: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    showWhiteWave: {
      type: Boolean,
      required: false,
      default: false,
    },
    showBlueWaves: {
      type: Boolean,
      required: false,
      default: false,
    },
    showBlueWaves3: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDarkerWave: {
      type: Boolean,
      required: false,
      default: false,
    },
    showPurpleWaves: {
      type: Boolean,
      required: false,
      default: false,
    },
    showLightBlueWaves: {
      type: Boolean,
      required: false,
      default: false,
    },
    imageName: {
      type: String,
      required: false,
    },
    hasButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonUrl: {
      type: String,
      required: false,
      default: '/',
    },
    buttonText: {
      type: String,
      required: false,
      default: 'default',
    },
  },
};
</script>

<style scoped>
.section--hero {
  background-color: var(--light-1);
  padding-block: 2rem 4rem;
  padding-bottom: 8rem !important;
}

.section__subtitle {
  color: var(--accent-red);
  text-transform: capitalize;
  text-align: left;
}

.section__title {
  letter-spacing: 0%;
  line-height: 120%;
  text-align: left;
  font-size: 1.8rem !important;
}

@media only screen and (min-width: 800px) {
  .section__title {
    letter-spacing: 0%;
    line-height: 120%;
    text-align: left;
    font-size: 2.5rem !important;
  }
}

.section__description {
  text-align: left;
  font-size: 1rem !important;
}

.wave {
  background: url('@/assets/img/wave-vector.svg'), var(--light-1);
  background-position: center bottom -1px;
  background-size: 101vw;
  padding-bottom: 5rem;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 700px) {
  .wave {
    padding-bottom: 7rem;
  }
}

@media only screen and (min-width: 1200px) {
  .wave {
    padding-bottom: 13rem;
  }
}

.blueWave {
  background: url('@/assets/img/wave-bg-lighten.svg'), var(--light-1);
  background-position: center bottom -1px;
  background-size: 101vw;
  padding-bottom: 5rem;
  background-repeat: no-repeat;
}
.purpleWave {
  background: url('@/assets/img/wave-vector-purple.svg'), var(--light-1);
  background-position: center bottom -1px;
  background-size: 101vw;
  padding-bottom: 5rem;
  background-repeat: no-repeat;
}
.lightBlueWave {
  background: url('@/assets/img/wave-vector-light-blue.svg'), var(--light-1);
  background-position: center bottom -1px;
  background-size: 101vw;
  padding-bottom: 5rem;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 700px) {
  .blueWave {
    padding-bottom: 7rem;
  }
}

@media only screen and (min-width: 1200px) {
  .blueWave {
    padding-bottom: 13rem;
  }
}

.blueWave3 {
  background: url('@/assets/img/developer-hiring-bg.svg'), var(--light-1);
  background-position: center bottom -1px;
  background-size: 101vw;
  padding-bottom: 5rem;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 700px) {
  .blueWave3 {
    padding-bottom: 7rem;
  }
}

@media only screen and (min-width: 1200px) {
  .blueWave3 {
    padding-bottom: 13rem;
  }
}

.darkerWave {
  background: url('@/assets/img/developer-hiring-bg-2.svg'), var(--light-1);
  background-position: center bottom -1px;
  background-size: 101vw;
  padding-bottom: 5rem;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 700px) {
  .darkerWave {
    padding-bottom: 7rem;
  }
}

@media only screen and (min-width: 1200px) {
  .darkerWave {
    padding-bottom: 13rem;
  }
}

.m-section__image {
  border-radius: 0.8rem;
  width: 100%;
  aspect-ratio: 16/9;
  margin-inline: auto;
  margin-block: 2rem;
}

@media only screen and (min-width: 700px) {
  .m-section__image {
    max-height: 300px;
  }
}

@media only screen and (min-width: 960px) {
  .m-section__image {
    display: none;
  }
}

@media only screen and (min-width: 1000px) {
  .text__container {
    width: 95%;
    margin-right: auto;
  }
}

@media only screen and (min-width: 1200px) {
  .text__container {
    width: 90%;
    margin-right: auto;
  }
}

.hero__btn {
  text-decoration: none;
  text-transform: capitalize;
  margin-top: 3rem;
  margin-right: auto;
  background: none;
  color: var(--accent-red);
}

.hero__btn:hover {
  text-decoration: underline;
}
</style>
