<template>
  <nav>
    <!-- START: About Menu -->
    <a class="m400 header__link" href="/about">About</a>
    <!-- END: About Menu -->
    <!-- START: DIGU Cares Menu -->
    <a class="m400 header__link" href="/care">DIGU Cares</a>
    <!-- END: DIGU Cares Menu  -->
    <!-- START: For Business Menu -->
    <v-menu
      open-on-hover
      open-delay="100"
      close-delay="100"
      location="bottom center"
      transition="slide-y-transition"
    >
      <template v-slot:activator="{ props }">
        <p class="m400" v-bind="props">
          For Businesses
          <v-icon class="dropdown-icon">mdi-chevron-down</v-icon>
        </p>
      </template>
      <v-list class="dropdown-menu" elevation="1">
        <v-list-item
          class="dropdown-menu__link"
          href="/why-digu-for-businesses"
        >
          <v-list-item-text class="dropdown-menu__text"
            ><a class="m400 dropdown__link" href="/for-hiring-companies"
              >Why DIGU for Businesses</a
            ></v-list-item-text
          >
        </v-list-item>
        <v-menu
          open-on-hover
          open-delay="100"
          close-delay="100"
          location="right top"
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ props }">
            <v-list-item
              class="dropdown-menu__link"
              style="cursor: pointer"
              v-bind="props"
            >
              <p class="m400">
                Services
                <v-icon class="dropdown-icon">mdi-chevron-right</v-icon>
              </p>
            </v-list-item>
          </template>
          <v-list
            class="dropdown-menu"
            elevation="2"
            style="margin-left: 0.5rem"
          >
            <v-list-item
              class="dropdown-menu__link"
              href="/hire-a-professional"
            >
              <v-list-item-text class="dropdown-menu__text"
                ><a class="m400 dropdown__link" href="/hire-a-professional"
                  >Hire a Professional</a
                ></v-list-item-text
              >
            </v-list-item>
            <v-list-item
              class="dropdown-menu__link"
              href="/outsource-your-project"
            >
              <v-list-item-text class="dropdown-menu__text"
                ><a class="m400 dropdown__link" href="/outsource-your-project"
                  >Outsource your Project</a
                ></v-list-item-text
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list>
    </v-menu>
    <!-- END: For Business Menu -->
    <!-- START: For Job Seekers Menu -->
    <v-menu
      open-on-hover
      open-delay="100"
      close-delay="100"
      location="bottom center"
      transition="slide-y-transition"
    >
      <template v-slot:activator="{ props }">
        <p class="m400" v-bind="props">
          For Job Seekers
          <v-icon class="dropdown-icon">mdi-chevron-down</v-icon>
        </p>
      </template>
      <v-list class="dropdown-menu" elevation="1">
        <v-list-item
          class="dropdown-menu__link"
          href="/why-digu-for-career-opportunities"
        >
          <v-list-item-text class="dropdown-menu__text"
            ><a class="m400 dropdown__link" href="/why-digu-for-career-opportunities"
              >Why DIGU For Career Opportunities</a
            ></v-list-item-text
          >
        </v-list-item>
        <v-list-item class="dropdown-menu__link" href="/job-placement-program">
          <v-list-item-text class="dropdown-menu__text"
            ><a class="m400 dropdown__link" href="/job-placement-program"
              >Learn about our Job Placement Program</a
            ></v-list-item-text
          >
        </v-list-item>
        <v-list-item class="dropdown-menu__link" href="/join-our-talent-pool">
          <v-list-item-text class="dropdown-menu__text"
            ><a class="m400 dropdown__link" href="/talents-on-demand"
              >Join Our Talent Pool</a
            ></v-list-item-text
          >
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- END: For Job Seekers Menu -->
    <!-- START: Contact Menu -->
    <a class="m400 header__link" href="/contact">Contact</a>
    <!-- END: Contact Menu -->
  </nav>
</template>

<script>
export default {
  name: 'DesktopMenu',
  data: () => ({
    menu: [{}],
  }),
};
</script>

<style scoped>
nav {
  display: none;
  align-items: center;
  justify-content: right;
  padding-block: 1.7rem;
  padding-inline: 0px;
}

@media only screen and (min-width: 1282px) {
  nav {
    display: flex;
  }
}

nav a,
p {
  color: var(--dark-2);
  font-size: 1rem;
  text-decoration: none;
  font-weight: 500;
  padding-inline: 0.5rem;
  margin-right: 1rem;
  cursor: pointer;
}

.header__link:last-child {
  margin-right: 0px;
}

.header__link.a-exact-active {
  font-weight: 700;
  border-bottom: 3px solid var(--primary-color-lighten);
}

/* Dropdown Menu */
.dropdown-menu {
  border-radius: 0.4rem !important;
  margin-top: 1rem;
  width: 20rem;
}

.dropdown-menu > .dropdown-menu__link {
  border-bottom: 1px solid var(--light-1);
  padding: 1rem;
}

.dropdown-menu__link > .dropdown-menu__text {
  display: flex;
}

.dropdown-menu > .dropdown-menu__link:hover {
  background-color: var(--light-1) !important;
}

.dropdown-menu > .dropdown-menu__link:last-child {
  border-bottom: none;
}

.dropdown-menu .dropdown__link {
  text-decoration: none;
  color: var(--dark-2);
}

.dropdown__link.a-exact-active {
  font-weight: bold;
}

.dropdown-icon {
  color: var(--primary-color-lighten);
}
</style>
