<template>
  <section class="section--hero">
    <div class="section__top">
      <p class="o500 section__sub-title">Contact Us</p>
      <h1 class="dms700 section__title">
        Get In Touch With Us For More Information
      </h1>
    </div>
    <div class="section__mid">
      <v-row class="address__row">
        <v-col class="address__col" cols="12" sm="6" lg="4" xl="3">
          <div class="address__card address--hq">
            <div class="address__header">
              <div class="address__image"></div>
            </div>

            <div class="address__body">
              <div class="generic-display">
                <p class="address__city o500">San Francisco</p>
                <p class="address__type m700">DIGU, LLC</p>
                <p class="address__address m400">
                  166 Geary Str STE 1500 #1230,<br />
                  San Francisco, CA 94108, USA
                </p>
                <p class="address__email">
                  <a href="mailto:sales@digu.one" class="mail-link m500"
                    >sales@digu.one</a
                  >
                </p>
                <p class="address__phone-numbers">
                  <a href="tel:+1 (833) 596 3361" class="phone-link m500"
                    >+1 (833) 596 3361</a
                  >
                </p>
              </div>
            </div>
          </div>
        </v-col>
        <v-col class="address__col" cols="12" sm="6" lg="4" xl="3">
          <div class="address__card address--central">
            <div class="address__header">
              <div class="address__image"></div>
            </div>
            <div class="address__body">
              <div class="generic-display">
                <p class="address__city o500">Kathmandu</p>
                <p class="address__type m700">DIGU Central</p>
                <p class="address__address m400">
                  92/9 Sai Marg, Bansbari,<br />
                  Kathmandu, 44600, Nepal
                </p>
                <p class="address__email">
                  <a href="mailto:careers@digu.one" class="mail-link m500"
                    >careers@digu.one</a
                  >
                </p>
                <p class="address__phone-numbers">
                  <a href="tel:+977-9802826474" class="phone-link m500"
                    >+977-9820550022</a
                  >
                </p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="section__buffer"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeroSection',
};
</script>

<style scoped>
.section__hero {
  background-color: var(--secondary-color-lighten);
}

.section__top {
  height: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('@/assets/img/welt.svg');
  background-color: var(--light-1);
  background-size: 600px 295px;
  background-position: bottom;
  padding-inline: 2rem;
}

@media only screen and (min-width: 1280px) {
  .section__top {
    padding-inline: none;
  }
}

.section__sub-title {
  color: var(--accent-red);
  font-size: 1.5rem;
  line-height: 120%;
  margin-bottom: 0.5rem;
}

.section__title {
  color: var(--dark-2);
  font-size: 2rem;
  text-transform: capitalize;
  line-height: 120%;
  margin-bottom: 0.5rem;
}

@media only screen and (min-width: 1280px) {
  .section__title {
    font-size: 2.5rem;
    text-transform: capitalize;
    line-height: 120%;
    margin-bottom: 0.5rem;
  }
}

.section__description {
  color: var(--dark-2);
  font-size: 1rem;
  line-height: 150%;
}

.section__mid {
  background-image: linear-gradient(
      #00000000 30%,
      var(--secondary-color-lighten) 95%
    ),
    url('@/assets/img/contact-hero-bg.svg');
  background-color: var(--light-1);
}

@media only screen and (min-width: 1280px) {
  .section__mid {
    background-size: cover;
    background-position-x: center;
    background-position-y: 10rem;
  }
}

@media only screen and (min-width: 700px) {
  .section__mid {
    background-image: linear-gradient(
        #00000000 70%,
        var(--secondary-color-lighten) 95%
      ),
      url('@/assets/img/contact-hero-bg.svg');
  }
}

@media only screen and (min-width: 1900px) {
  .section__mid {
    background-image: linear-gradient(
        #00000000 90%,
        var(--secondary-color-lighten) 95%
      ),
      url('@/assets/img/contact-hero-bg.svg');
  }
}

@media only screen and (min-width: 2000px) {
  .section__mid {
    background-image: linear-gradient(
        #00000000 90%,
        var(--secondary-color-lighten) 95%
      ),
      url('@/assets/img/contact-hero-bg.svg');
    background-size: cover;
    background-position-y: top;
  }
}

.address__row {
  padding-top: 4rem;
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 1400px) {
  .address__row {
    padding-top: 10rem;
  }
}

@media only screen and (min-width: 1024px) {
  .address__col {
    padding: 1rem;
  }
}

@media only screen and (min-width: 1280px) {
  .address__col {
    position: relative;
  }
}

.address__card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  background-color: var(--light-1);
  border-radius: 1.5rem;
  width: 100%;
}

@media only screen and (min-width: 700px) {
  .address__card {
    max-width: 45vw;
  }
}

@media only screen and (min-width: 900px) {
  .address__card {
    max-width: 35vw;
  }
}

@media only screen and (min-width: 1280px) {
  .address__card {
    max-width: 27rem;
  }
}

.address__card.address--hq {
  margin-inline: auto 0px;
}

.address__card.address--central {
  margin-inline: 0px auto;
}

.address__header {
  width: 100%;
  overflow: hidden;
}

.address__image {
  display: block;
  width: 100%;
  min-height: 12rem;
  background-position: center;
  background-size: cover;
  border-top-right-radius: 0.8rem;
  border-top-left-radius: 0.8rem;
}

@media only screen and (min-widht: 1280px) {
  .address__image {
    width: 23rem;
    min-height: 12rem;
  }
}

.address--hq .address__image {
  background-image: url('@/assets/img/san-diego.webp');
}

.address--central .address__image {
  background-image: url('@/assets/img/ktm.webp');
}

.address__body {
  width: 100%;
  margin-block: 2rem;
  text-align: left;
  overflow: hidden;
}

.address__city {
  color: var(--accent-red);
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.address__type {
  color: var(--primary-color-lighten);
  font-size: 1.1rem;
  margin-bottom: 0.3rem;
}

.hidden-display {
  margin-top: 2rem;
}

.address__email {
  margin-top: 1rem;
}

.address__email .mail-link {
  text-decoration: none;
  color: var(--primary-color-lighten);
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
}

.address__phone-numbers {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
}

.address__phone-numbers .phone-link {
  text-decoration: none;
  font-family: 'Manrope', sans-serif;
  color: var(--dark-2);
}

.address__map {
  margin-top: 1rem;
}

.hidden {
  display: block;
  margin: 0px;
  padding: 0px;
  height: 0px;
  transition: height 900ms ease-in-out;
}

.hidden_ {
  margin: initial !important;
  padding: initial !important;
  height: 17rem !important;
  overflow: inherit !important;
}

.address__footer {
  width: 100%;
}

.address__footer .address__action {
  width: 100%;
  text-transform: capitalize;
  color: var(--primary-color-darken);
}
</style>
