const blogs = {
  divacs: {
    companyName: 'DIVACS',
    slug: 'divacs',
    bg: 'cares_programs.jpeg',
    subTitle: 'DIVACS',
    title: 'DIVACS Impact Stories',
    mainSection: {
      featureImage: 'cares_programs.jpeg',
      mainSectionParagraph:
        'DIVACS is dedicated to consolidating pre-sales, post-sale, and customer values into a unified and transparent platform',
      mainSectionSecondaryParagraph: '',
    },
    challengeSolutionSection: {
      sectionTitle: 'How our partnership with DIVACS impacted lives in Nepal?',
      infographicSection: [
        {
          id: '1',
          sectionPercentage: '3',
          sectionDescription: 'Professionals Hired By DIVACS',
        },
        {
          id: '2',
          sectionPercentage: '1',
          sectionDescription: 'Professionals Hired By DIGU',
        },
      ],
      challengeSection: {
        paragraphs: [
          'A newly established company with limited resources and financial constraints needed to find a way to scale their hiring effeciently while minimizing costs.',
          'Single founder with a technical background but no time to code. The company saw an increasing demand from investors and prospects to deliver a working POC.',
          'Struggled to find reliable, skilled talent essential for the business.',
          'Recognized inefficiencies in traditional hiring methods that led to a bad hiring experience.',
          'Challenges in quickly scaling the engineering team.',
          'Challenges finding a customer-first, reliable, and trusted partner to help grow the business. The partner needed to handle payroll support and ensure compliance with international regulations.',
        ],
      },
      solutionSection: {
        paragraphs: [
          'DIVACS hired 2 Senior Full Stack Developers and 1 Sr. UI/UX Designer to support the business and meet deadlines.',
          'DIGU set up interviews with pre-vetted professionals',
          'Senior resources have been providing technical expertise, maximizing value for the customer',
          'The POC was a success, and the customer is now moving forward with full-scale product development',
          'White-gloved customer service to ensure project success',
          'Customer is 100% satisfied and will be expanding their team with DIGU',
        ],
      },
      productsSection: {
        paragraphs: [
          'Streamlined recruitment process - hired exceptional candidates in less than 45 days',
          '100% placement success rate',
          'Improved project efficiency by 50% and increased project velocity by 2X',
          '100% accuracy in payroll processing and reduction of administrative overhead',
          '100% compliance with Nepalese labor laws',
          'High-Value Talent at Optimized Costs',
          'Achieved up to 50% savings on total employment or contracting costs per hire.',
          "Streamlined recruitment process - reduced finder's fees by over 50%.",
          'Achieved a 30% savings on employee benefit costs',
        ],
      },
    },
    moreInfoSection: [
      {
        title: 'Users',
        description: '5,000',
      },
      {
        title: 'Location',
        description: 'Smartworks Central',
      },
      {
        title: 'Industry',
        description: 'Agriculture',
      },
    ],
    testimonials: [
      {
        image: 'ujjwal_raja_maharjan.webp',
        paragraphs: [
          'My experience with Divacs through DIGU has been immensely rewarding. Collaborating with a US-based client enhanced my skills and confidence, while the higher salary and benefits provided financial stability. The supportive environment not only boosted my professional growth but also helped me balance personal goals and relationships.',
        ],
        name: 'Ujjwal Raja Maharjan',
        designation: 'Senior Software Developer',
        companyName: 'DIVACS',
      },
      {
        image: 'rubin_maharjan.webp',
        paragraphs: [
          "Senior Product Designer Working with Divacs through DIGU has been transformative. As a product designer aiming to learn about the US startup culture, this experience broadened my perspective and advanced my skills. It's been a journey of growth and discovery, with invaluable support from the DIGU team shaping my career path.",
        ],
        name: 'Rubin Maharjan',
        designation: 'Senior Product Designer',
        companyName: 'DIVACS',
      },
    ],
  },
};

export default blogs;
