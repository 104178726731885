<template>
  <div class="service" id="view">
    <!-- TODO: mark this as true when we fix verified badge section -->
    <hero-section :title="title" :subTitle="subTitle" :description="description" :showDarkerWave="true" :hasButton="true"
      buttonText="Learn more about Verified Professionals" buttonUrl="/verified-badges"
      imageName="for-developers-hero.webp" />
    <new-main-container />
    <neu-footer-sektion />
  </div>
</template>

<script>
import HeroSectionVue from "@/components/partials/HeroSection.vue";
import NewMainContainer from "./Sections/NewMainContainer.vue";
import NeuFooterSektion from "@/views/about/Sections/NeuFooterSektion.vue";
export default {
  name: "DSAAS",
  components: {
    "hero-section": HeroSectionVue,
    "new-main-container": NewMainContainer,
    "neu-footer-sektion": NeuFooterSektion
  },
  data: () => ({
    title: "Hire a Professional",
    subTitle: "Our Services",
    description:
      "If you need a skilled professional without breaking the bank, choose from our verified talent pool. Our professionals are interview-ready and equipped to meet your needs. We’ll work closely with you to find the perfect match for your team.",
  }),
  created() {
    document.getElementById("loaderSection").classList.add("d-none");
  },
};
</script>

